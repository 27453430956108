import { BaseManager } from "./baseManager";
import { storeKeys } from "../plugins/dataStore";
import { iSection } from '../types';

export interface iSectionCreate {
  departmentId: number;
  sectionCode: number;
  warehouseCode: number;
}

export interface iSectionUpdate {
  departmentId?: number;
  sectionCode?: number;
  warehouseCode?: number;
}

class SectionsManager extends BaseManager<iSection, iSectionCreate, iSectionUpdate> { }

export const sections = new SectionsManager("/api/sections/", storeKeys.sections);
