import { deliveryType, iDeliveryRoute, iDeliveryStop, iIssueGroup } from "../types";
import moment from "moment";
import { multiPush } from "./issues"
import { mgr } from ".";
import { formatSeconds } from "../utils/time";

export const deliveryRoutes = {
  validate(x: iDeliveryRoute, stopsForRoute?: iDeliveryStop[]): iIssueGroup {
    const group: iIssueGroup = { missing: [], errors: [], warnings: [] }
    multiPush(this, group, x, "deliveryRoute", "distanceKm", stopsForRoute)
    multiPush(this, group, x, "deliveryRoute", "driverId", stopsForRoute)
    multiPush(this, group, x, "deliveryRoute", "outsourcedDriverName", stopsForRoute)
    multiPush(this, group, x, "deliveryRoute", "departedUtc", stopsForRoute)
    multiPush(this, group, x, "deliveryRoute", "returnedUtc", stopsForRoute)
    multiPush(this, group, x, "deliveryRoute", "scheduledDepartureUtc", stopsForRoute)
    return group
  },
  trailerCode: { missing(x: iDeliveryRoute) { return !x.trailerCode ? "Missing" : null }, missingOrErrors(x: iDeliveryRoute) { return this.missing(x) } },
  distanceKm: {
    missing(x: iDeliveryRoute) {
      if (!x.distanceKm) return "Missing"
      return null
    },
    errors(x: iDeliveryRoute) {
      if (!x.distanceKm) return null
      if (x.distanceKm > 4000) return "Value too large"
      if (x.distanceKm < 1) return "Value too small"
      return null
    },
    missingOrErrors(x: iDeliveryRoute) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    }
  },
  driverId: {
    missing(x: iDeliveryRoute) {
      // If its  a spar truck there must be driverId
      if (isSparCode(x.trailerCode) && !x.driverId && !x.outsourcedDriverName) return "Missing";
      return null
    },
    errors(x: iDeliveryRoute) {
      if (!isSparCode(x.trailerCode) && x.driverId) return "Spar driver driving an outsourced vehicle";
      return null
    },
    missingOrErrors(x: iDeliveryRoute) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    },
  },
  outsourcedDriverName: {
    missing(x: iDeliveryRoute) {
      // If its not a spar truck there must be an outsourcedDriverName
      if (!isSparCode(x.trailerCode) && !x.outsourcedDriverName) return "Missing";
      return null
    },
    errors(x: iDeliveryRoute) {
      return null
    },
    missingOrErrors(x: iDeliveryRoute) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    },
  },
  departedUtc: {
    missing(x: iDeliveryRoute) {
      if (!x.departedUtc) return "Missing"
      return null
    },
    errors(x: iDeliveryRoute, stopsForRoute?: iDeliveryStop[]) {
      if (!stopsForRoute) stopsForRoute = mgr.deliveryStops.getAll().filter(a => a.deliveryRouteId == x.id)
      if (stopsForRoute.some((a: iDeliveryStop) => moment(a.arrivedUtc).isSameOrBefore(x.departedUtc)))
        return "A stop arrives before it departs";
      return null
    },
    warnings(x: iDeliveryRoute) {
      const deliveryStopsForRoute = mgr.deliveryStops.getAll().filter(a => a.deliveryRouteId == x.id)

      if (travelToDurationIsExtreme(x, deliveryStopsForRoute)) return [
        `The time between departing the DC and arriving at the first stop looks wrong.`,
        `${formatSeconds(travelToDuration(x, deliveryStopsForRoute))} for ${travelToDistance(deliveryStopsForRoute)} km?`,
        `Average speed:${travelToAveHph(x, deliveryStopsForRoute).toFixed(1)} km/h?`
      ]
      return null
    },
    missingOrErrors(x: iDeliveryRoute) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    },
  },
  returnedUtc: {
    missing(x: iDeliveryRoute) {
      // If its a spar vehicle it must have a returnedUtc
      if (!x.returnedUtc && isSparCode(x.trailerCode)) return "Missing"
      return null
    },
    errors(x: iDeliveryRoute, stopsForRoute?: iDeliveryStop[]) {
      if (!stopsForRoute) stopsForRoute = mgr.deliveryStops.getAll().filter(a => a.deliveryRouteId == x.id)
      if (stopsForRoute.some((a: iDeliveryStop) => moment(x.returnedUtc).isBefore(a.departedUtc)))
        return "Route returns before a departed time";
      return null
    },
    warnings(x: iDeliveryRoute) {
      const deliveryStopsForRoute = mgr.deliveryStops.getAll().filter(a => a.deliveryRouteId == x.id)
      if (travelReturnDurationIsExtreme(x, deliveryStopsForRoute)) return [
        `The time between leaving last stop and returning to DC looks wrong.`,
        `${formatSeconds(travelReturnDuration(x, deliveryStopsForRoute))} for ${travelReturnDistance(deliveryStopsForRoute)} km?`,
        `Average speed:${travelReturnAveHph(x, deliveryStopsForRoute).toFixed(1)} km/h?`
      ]
      return null
    },
    missingOrErrors(x: iDeliveryRoute) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    },
  },
  scheduledDepartureUtc: {
    missing(x: iDeliveryRoute, stopsForRoute?: iDeliveryStop[]) {
      // Try find the first stop
      if (!stopsForRoute || stopsForRoute.length < 1) stopsForRoute = mgr.deliveryStops.getAll()
      stopsForRoute = stopsForRoute
        .filter((stop) => stop.deliveryRouteId === x.id)
        .sort((a, b) => a.stopNum > b.stopNum ? 1 : b.stopNum > a.stopNum ? -1 : 0);
      const firstStop = stopsForRoute.length > 0 ? stopsForRoute[0] : undefined

      // If the first stop is scheduled then there must be a scheduledDepartureUtc
      if (!x.scheduledDepartureUtc && firstStop?.deliveryType == deliveryType.scheduled) return "Missing"
      return null
    },
    errors(x: iDeliveryRoute, stopsForRoute?: iDeliveryStop[]) {
      if (!stopsForRoute) stopsForRoute = mgr.deliveryStops.getAll().filter(a => a.deliveryRouteId == x.id)
      if (stopsForRoute.length === 0) return null;

      const firstStop = stopsForRoute.sort((a: iDeliveryStop, b: iDeliveryStop) =>
        a.stopNum > b.stopNum ? 1 : b.stopNum > a.stopNum ? -1 : 0
      )[0];

      if (firstStop.deliveryType === deliveryType.scheduled && x.scheduledDepartureUtc === null)
        return "Must have a time if the first stops delivery type is: scheduled";
      return null
    },
    missingOrErrors(x: iDeliveryRoute) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    },
  },
}

function isSparCode(code: string): boolean {
  if (!code) return false;
  return /^(C[0-9]|SP[0-9]|WC[0-9]|SUPPERENT)/.test(code);
}


function travelToDuration(route: iDeliveryRoute, stopsForRoute: iDeliveryStop[]) {
  // the number of seconds from departing the DC to arriving at the first stop
  if (stopsForRoute.length <= 0) return 0;
  const deliveryRoute = route;
  const firstStop = stopsForRoute[0];
  const departed = moment(deliveryRoute.departedUtc);
  const arrived = moment(firstStop.arrivedUtc);
  return arrived.diff(departed, "seconds");
}
function travelToDistance(stopsForRoute: iDeliveryStop[]) {
  // Km in one direction
  if (stopsForRoute?.length <= 0) return 0;
  const firstStop = stopsForRoute[0];
  const firstLocation = mgr.deliveryLocations.getById(firstStop.deliveryLocationId)
  if (!firstLocation) return 0
  return firstLocation.distanceFromDc;
}

function travelReturnDuration(route: iDeliveryRoute, stopsForRoute: iDeliveryStop[]) {
  // the number of seconds from leaving the last stop to arriving back at the DC
  if (stopsForRoute?.length <= 0) return 0;
  const deliveryRoute = route;
  const lastStop = stopsForRoute[stopsForRoute.length - 1];
  const returned = moment(deliveryRoute.returnedUtc);
  const departed = moment(lastStop.departedUtc);
  return returned.diff(departed, "seconds");
}
function travelReturnDistance(stopsForRoute: iDeliveryStop[]) {
  // Km in one direction
  if (stopsForRoute?.length <= 0) return 0;
  const lastStop = stopsForRoute[stopsForRoute.length - 1];
  const lastLocation = mgr.deliveryLocations.getById(lastStop.deliveryLocationId);
  if (!lastLocation) return 0
  return lastLocation.distanceFromDc;
}
function travelToAveHph(route: iDeliveryRoute, stopsForRoute: iDeliveryStop[]) {
  if (stopsForRoute?.length <= 0) return 0;
  const duration = travelToDuration(route, stopsForRoute);
  const km = travelToDistance(stopsForRoute);
  const aveKph = km / (duration / 3600);
  return aveKph;
}
function travelReturnAveHph(route: iDeliveryRoute, stopsForRoute: iDeliveryStop[]) {
  if (stopsForRoute?.length <= 0) return 0;
  const duration = travelReturnDuration(route, stopsForRoute);
  const km = travelReturnDistance(stopsForRoute);
  const aveKph = km / (duration / 3600);
  return aveKph;
}
function travelToDurationIsExtreme(route: iDeliveryRoute, stopsForRoute: iDeliveryStop[]) {
  const aveKph = travelToAveHph(route, stopsForRoute);
  const minAveKph = 10;
  const maxAveKph = 130;
  if (aveKph < minAveKph || aveKph > maxAveKph) return true;
  return false;
}
function travelReturnDurationIsExtreme(route: iDeliveryRoute, stopsForRoute: iDeliveryStop[]) {
  const aveKph = travelReturnAveHph(route, stopsForRoute);
  const minAveKph = 10;
  const maxAveKph = 130;
  if (aveKph < minAveKph || aveKph > maxAveKph) return true;
  return false;
}

