import { BaseManager } from "./baseManager";
import { storeKeys } from "../plugins/dataStore";
import { iOvertimeType } from '../types';

export interface iOvertimeTypeCreate {
  startDate: string;
  endDate?: string;
  name: string
  rate: number
}

export interface iOvertimeTypeUpdate {
  startDate?: string;
  endDate?: string | null;
  name?: string
  rate?: number
  active?: boolean
}

class OvertimeManager extends BaseManager<iOvertimeType, iOvertimeTypeCreate, iOvertimeTypeUpdate> {
  getForDates(startDate: string, endDate: string | null = null) {
    if (endDate != null)
      return this.fetch(`${this.baseUrl}?start_date=${startDate}&end_date=${endDate}`)
    else
      return this.fetch(`${this.baseUrl}?start_date=${startDate}`)
  }
}

export const overtimeTypes = new OvertimeManager("/api/overtime-types/", storeKeys.overtimeTypes);
