import { BaseManager } from "./baseManager";
import { storeKeys } from "../plugins/dataStore";
import { iTransporter } from '../types';

export interface iTransporterCreate {
  name: string;
}

export interface iTransporterUpdate {
  name?: string;
  active?: boolean;
}

class TransportersManager extends BaseManager<iTransporter, iTransporterCreate, iTransporterUpdate> { }

export const transporters = new TransportersManager("/api/transporters/", storeKeys.transporters);
