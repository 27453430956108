import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { deliveryType, iDeliveryStop, loadType, stopType } from '../types';
import { localToUtc, utcToLocal } from '../utils/time';
import axios from "axios"

export interface iDeliveryStopCreate {
  deliveryRouteId: number;
  deliveryLocationId: number;
  stopType?: stopType;
  deliveryType: deliveryType;
  loadType?: loadType;
  stopNum: number;
  scheduledArrivalUtc?: string;
  arrivedUtc: string;
  departedUtc: string;
}

export interface iDeliveryStopUpdate {
  deliveryRouteId?: number;
  deliveryLocationId?: number;
  stopType?: stopType;
  deliveryType?: deliveryType;
  loadType?: loadType;
  stopNum?: number;
  scheduledArrivalUtc?: string;
  arrivedUtc?: string;
  departedUtc?: string;
}

class DeliveryStopManager extends BaseManager<iDeliveryStop, iDeliveryStopCreate, iDeliveryStopUpdate> {
  transformInbound(data: any) {
    data.arrivedUtc = utcToLocal(data.arrivedUtc);
    data.departedUtc = utcToLocal(data.departedUtc);
    data.scheduledArrivalUtc = utcToLocal(data.scheduledArrivalUtc);
    return data
  }

  transformOutbound(data: any) {
    if (data.arrivedUtc) data.arrivedUtc = localToUtc(data.arrivedUtc);
    if (data.departedUtc) data.departedUtc = localToUtc(data.departedUtc);
    if (data.scheduledArrivalUtc) data.scheduledArrivalUtc = localToUtc(data.scheduledArrivalUtc);
    return data
  }

  update(id: number, data: iDeliveryStopUpdate, merge = false, url = `${this.baseUrl}${id}/`) {
    return axios.patch(url, this.transformOutbound(data)).then((res) => {
      if (!res) return
      res.data = this.transformInbound(res.data)
      dataStore.upsert(storeKeys.deliveryStops, [res.data]);
      dataStore.upsert(storeKeys.deliveryStopsInitial, [res.data]);
      dataStore.remove(storeKeys.deliveryStopsEdits, [res.data]);
    });
  }
}

export const deliveryStops = new DeliveryStopManager("/api/delivery-stops/", storeKeys.deliveryStops);
