import Vue from "vue";
import Router from "vue-router";

import Home from "./views/Home.vue";
import Alerts from "./views/Alerts.vue";
import Stores from "./views/Stores.vue";
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        requiresAuth: false,
        closeSideMenu: true
      },
    },
    {
      path: "/alerts",
      name: "Alerts",
      component: Alerts,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/stores",
      name: "Stores",
      component: Stores,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: () => import(/* webpackChunkName: "ResetPassword" */ "./views/ResetPassword.vue"),
      meta: {
      },
    },
    {
      path: "/schedules",
      name: "Schedules",
      component: () => import(/* webpackChunkName: "ScheduledDeliveries" */ "./views/ScheduledDeliveries.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/access",
      name: "Access",
      component: () => import(/* webpackChunkName: "Access" */ "./views/Access.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/delivery-locations",
      name: "DeliveryLocations",
      component: () => import(/* webpackChunkName: "DeliveryLocations" */ "./views/DeliveryLocations.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/delivery-reports",
      name: "DeliveryReports",
      component: () => import(/* webpackChunkName: "DeliveryReports" */ "./views/DeliveryReports.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/telemetry-reports",
      name: "TelemetryReports",
      component: () => import(/* webpackChunkName: "TelemetryReports" */ "./views/TelemetryReports.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/pickers",
      name: "Pickers",
      component: () => import(/* webpackChunkName: "Pickers" */ "./views/Pickers.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/fleet-management",
      name: "FleetManagement",
      component: () => import(/* webpackChunkName: "FleetManagement" */ "./views/FleetManagement.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/picker-incentives",
      name: "PickerIncentives",
      component: () => import(/* webpackChunkName: "PickerIncentives" */ "./views/PickerIncentives.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/welcome",
      name: "Welcome",
      component: () => import(/* webpackChunkName: "Welcome" */ "./views/Welcome.vue"),
      meta: {
        requiresAuth: false,
        closeSideMenu: true
      },
    },
    {
      path: "/confirm-email",
      name: "ConfirmEmail",
      component: () => import(/* webpackChunkName: "ConfirmEmail" */ "./views/ConfirmEmail.vue"),
      meta: {
        requiresAuth: false,
        closeSideMenu: true
      }
    },
    {
      path: "/settings",
      name: "Settings",
      component: () => import(/* webpackChunkName: "Settings" */ "./views/Settings.vue"),
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: "/drivers",
      name: "Drivers",
      component: () => import(/* webpackChunkName: "Drivers" */ "./views/Drivers.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/driver-schedules",
      name: "DriverSchedules",
      component: () => import(/* webpackChunkName: "DriverSchedules" */ "./views/DriverSchedules.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/driver-reports",
      name: "DriverReports",
      component: () => import(/* webpackChunkName: "DriverReports" */ "./views/DriverReports.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/transporters",
      name: "Transporters",
      component: () => import(/* webpackChunkName: "Transporters" */ "./views/Transporters.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/sms-statuses",
      name: "Sms Statuses",
      component: () => import(/* webpackChunkName: "SmsStatus" */ "./views/SmsStatus.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/finance/tracking",
      name: "Finance Tracking",
      component: () => import(/* webpackChunkName: "Tracking" */ "./views/finance/Tracking.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/finance/budget",
      name: "financial Budget",
      component: () => import(/* webpackChunkName: "Budget" */ "./views/finance/Budget.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

export default router;
