import axios from "axios";
import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { iDeliveryLocation } from '../types';

export interface iDeliveryLocationCreate {
  name: string;
  longitude: number;
  latitude: number;
  distanceFromDc: number;
  targetOffloadTime: number;
  active?: boolean;
  stores: number[];
}

export interface iDeliveryLocationUpdate {
  name?: string;
  longitude?: number;
  latitude?: number;
  distanceFromDc?: number;
  targetOffloadTime?: number;
  active?: boolean;
  stores?: number[];
}

class DeliveryLocationManager extends BaseManager<iDeliveryLocation, iDeliveryLocationCreate, iDeliveryLocationUpdate> {
  fetch(url = this.baseUrl) {
    return axios.get(url).then((res) => {
      if (!res?.data?.deliveryLocations) return
      dataStore.replace(this.storeKey, res.data.deliveryLocations.map((x: any) => this.transformInbound(x)));
    });
  }
  fetchDeactivated(deactivated: boolean) {
    return this.fetch(`${this.baseUrl}?deactivated=${deactivated}`)
  }
}

export const deliveryLocations = new DeliveryLocationManager("/api/delivery-locations/", storeKeys.deliveryLocations);
