import moment from "moment";

export function utcToLocal(utc: string, formatString = ""): string {
  if (!utc) return "";
  let m = moment.utc(utc);
  if (m.isValid()) return m.local().format(formatString);
  else {
    console.error(`Could not convert utc string[${utc}] into a valid local time string`);
    return utc;
  }
}
export function localToUtc(local: string, formatString = ""): string {
  if (!local) return "";
  let m = moment(local);
  if (m.isValid())
    return m
      .utc()
      .format(formatString)
      .replace("Z", "");
  // Python does not like the Z
  else {
    console.error(`Could not convert local string[${local}] into a valid utc time string`);
    return local;
  }
}

// Used by crud table
export function isoTimeToString(isoTimeString: string): string {
  const date = new Date("2000-01-01T" + isoTimeString);
  const hh = date.getHours();
  const mm = date.getMinutes();
  return [(hh > 9 ? "" : "0") + hh, (mm > 9 ? "" : "0") + mm].join(":");
}
// Used by crud table
export function nonIsoTimeToIsoString(nonIsoTimeString: string): string {
  const date = new Date("2000-01-01T" + nonIsoTimeString);
  date.setHours(date.getHours() + 2); // Add 2 hours to compensate for different format
  const isoTime = date.toISOString().substr(11, 23); // time only
  return isoTime.replace("Z", "+02:00"); // Format for server
}

export const timeOfDay = {
  isoTimeStringToTimeOfDayString(isoTimeString: string): string {
    const date = new Date("2000-01-01T" + isoTimeString);
    const hh = date.getHours();
    const mm = date.getMinutes();
    return [(hh > 9 ? "" : "0") + hh, (mm > 9 ? "" : "0") + mm].join(":");
  },
  timeOfDayStringToIsoString(nonIsoTimeString: string): string {
    const date = new Date("2000-01-01T" + nonIsoTimeString);
    date.setHours(date.getHours() + 2); // Add 2 hours to compensate for different format
    const isoTime = date.toISOString().substr(11, 23); // time only
    return isoTime.replace("Z", "+02:00"); // Format for server
  },
};

export const timeLength = {
  fromSeconds(seconds: number): string {
    if (typeof seconds !== "number") seconds = 0;
    const date = new Date(seconds * 1000);
    return date.toISOString().substr(14, 5);
  },
  toSeconds(str: string): number {
    if (!str) return 0;
    const hms = str.split(":");
    if (hms.length === 2) hms.unshift("00");
    const hours = parseInt(hms[0]);
    const minutes = parseInt(hms[1]);
    const seconds = parseInt(hms[2]);
    return Math.round(hours * 3600 + minutes * 60 + seconds);
  },
  displayMmSs(str: string): string {
    if (typeof str !== "string") return "00:00";
    const hms = str.split(":");
    return hms[0] + ":" + hms[1];
  },
};

export function formatSeconds(seconds: number): string {
  let interval = seconds / 31536000;
  if (interval > 1) return Math.floor(interval) + " years";
  interval = seconds / 2592000;
  if (interval > 1) return Math.floor(interval) + " months";
  interval = seconds / 86400;
  if (interval > 1) return Math.floor(interval) + " days";
  interval = seconds / 3600;
  if (interval > 1) return Math.floor(interval) + " hours";
  interval = seconds / 60;
  if (interval > 1) return Math.floor(interval) + " minutes";
  return Math.floor(seconds) + " seconds";
}


export function firstWednesdayAfterDate(dateString: string, outputFormat: string = "YYYY-MM-DD") {
  let date = moment(dateString)
  while (date.format("ddd") != "Wed") {
    date = date.add(1, 'day')
  }
  return date.format(outputFormat)
}