import { render, staticRenderFns } from "./StoresUpdate.vue?vue&type=template&id=83bf2d70&scoped=true&"
import script from "./StoresUpdate.vue?vue&type=script&lang=ts&"
export * from "./StoresUpdate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83bf2d70",
  null
  
)

export default component.exports