import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { localToUtc, utcToLocal } from '../utils/time';
import { iTransporter, iDeliveryLocation, iTransporterRouteAgreementAdjustment } from '../types';
import { iTransporterBaseCpk } from "./transporterBaseCpks";
import axios from 'axios';

export interface iTransporterRouteAgreement {
  id: number;
  startDate: string;
  endDate: string | null;
  transporter: iTransporter;
  baseCpk: iTransporterBaseCpk;
  distanceKm: number;
  deliveryLocations: iDeliveryLocation[];
  adjustments: iTransporterRouteAgreementAdjustment[];
  price: number;
  active: boolean;
  createdUtc: string;
  confirmedUtc: string | null;
}

export interface iTransporterAgreementAdjustmentCreate {
  description?: string;
  value: number
}
export interface iTransporterAgreementAdjustmentUpdate {
  id?: number;
  value: number
  description?: string;
}

export interface iTransporterRouteAgreementCreate {
  date: string
  transporterId: number
  baseCpkId: number
  distanceKm: number
  deliveryLocationIds: number[]
  adjustments: iTransporterAgreementAdjustmentCreate[]
}

export interface iTransporterRouteAgreementUpdate {
  transporterId?: number
  baseCpkId?: number
  adjustments?: iTransporterAgreementAdjustmentUpdate[]
  deliveryLocationIds?: number[]
  distanceKm?: number
  active?: boolean;
}

export interface iTransporterRouteAgreementBulkUpdate {
  ids: number[]
  transporterId?: number
  baseCpkId?: number
  deliveryLocationIds?: number[]
  distanceKm?: number
  active?: boolean;
}

class TransporterRouteAgreementsManager extends BaseManager<iTransporterRouteAgreement, iTransporterRouteAgreementCreate, iTransporterRouteAgreementUpdate> {

  fetchForDate(date: string) {
    return this.fetch(`${this.baseUrl}${date}/`)
  }

  cloneForMonth(date: string) {
    return axios.post(`${this.baseUrl}clone-into/${date}/`).then((res) => {
      if (!res) return
      dataStore.replace(this.storeKey, res.data.map((x: any) => this.transformInbound(x)));
    });
  }

  transformInbound(data: any) {
    if (data.confirmedUtc) data.confirmedUtc = utcToLocal(data.confirmedUtc);
    return data
  }

  bulkUpdate(updateDate: iTransporterRouteAgreementBulkUpdate) {
    return axios.patch(`${this.baseUrl}bulk-update/`, updateDate).then((res) => {
      if (!res) return
      dataStore.upsert(this.storeKey, res.data.map((x: any) => this.transformInbound(x)));
    });
  }

  confirmRouteAgreement(routeAgreements: iTransporterRouteAgreement[]) {
    const ids = routeAgreements.map(x => x.id)
    return axios.patch(`${this.baseUrl}confirm/`, { ids: ids }).then((res) => {
      if (!res) return
      dataStore.upsert(this.storeKey, res.data.map((x: any) => this.transformInbound(x)));
    });
  }
}

export const transporterRouteAgreements = new TransporterRouteAgreementsManager("/api/transporter-route-agreements/", storeKeys.transporterRouteAgreements);
