import { BaseManager } from "./baseManager";
import { storeKeys } from "../plugins/dataStore";
import { iPenaltyType } from '../types';

export interface iPenaltyTypeCreate {
  startDate: string;
  endDate?: string;
  name: string
  amount?: number
}

export interface iPenaltyTypeUpdate {
  startDate?: string;
  endDate?: string | null;
  name?: string
  amount?: number
  active?: boolean
}

class PenaltyTypeManager extends BaseManager<iPenaltyType, iPenaltyTypeCreate, iPenaltyTypeUpdate> {
  getForDates(startDate: string, endDate: string | null = null) {
    if (endDate != null)
      return this.fetch(`${this.baseUrl}?start_date=${startDate}&end_date=${endDate}`)
    else
      return this.fetch(`${this.baseUrl}?start_date=${startDate}`)
  }
}

export const penaltyTypes = new PenaltyTypeManager("/api/penalty-types/", storeKeys.penaltyTypes);
