import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import axios from 'axios';

export enum routeCostAuthorizationStatus {
  FLAGGED = "FLAGGED",
  REVIEWED = "REVIEWED"
}

export interface iTransporterCostRecon {
  routeId: number;
  routeDate: string;
  deliveryLocations: string[];
  transporter: string;
  predictedCost: number;
  actualCost: number | null;
  predictionMethod: string;
  status: string;
  invoiceNumber: string | null;
  comment: string | null;
  authorizationStatus: routeCostAuthorizationStatus | null
}

export interface iTransporterCostReconCreate {
  routeId: number;
  actualCost: number;
  estimatedCost?: number;
  predictionMethod?: string | null;
  invoiceNumber?: string | null;
  comment?: string | null;
  authorizationStatus?: routeCostAuthorizationStatus | null
}

export interface iTransporterCostReconUpdate {
  routeId: number;
  actualCost?: number;
  estimatedCost?: number;
  predictionMethod?: string | null;
  invoiceNumber?: string | null;
  comment?: string | null;
  authorizationStatus?: routeCostAuthorizationStatus | null
}

export interface iTransporterCostReconUpdates {
  updates: iTransporterCostReconUpdate[];
  deletes: number[]; // routeIds
}

class TransporterCostReconciliationManager extends BaseManager<iTransporterCostRecon, iTransporterCostReconCreate, iTransporterCostReconUpdate> {

  fetchForPeriod(fromDate: string, toDate: string,) {
    return this.fetch(`${this.baseUrl}?startDate=${fromDate}&endDate=${toDate}`)
  }

  fetchAuthorizations() {
    return axios.get(`${this.baseUrl}authorizations/`).then((res) => {
      if (!res) return
      return res.data.map((x: any) => this.transformInbound(x));
    });
  }

  updateCosts(updates: iTransporterCostReconUpdates) {
    return axios.patch(`${this.baseUrl}`, updates).then((res) => {
      if (!res) return
      dataStore.upsert(this.storeKey, [this.transformInbound(res.data)], true);
    });
  }

  evaluateActualCostFormula(actualCostFormula: string) {
    try {
      // replace all characters in formula except for digits and operators
      const formula = actualCostFormula.replace(/[^-()\d/*+.]/g, "");

      // evaluate formula string
      const operatorIndices = [];
      for (let i = 0; i < formula.length; i++) {
        if (formula[i] === "+" || formula[i] === "-") operatorIndices.push(i);
      }

      const formulaVariables = [];
      for (let i = 0; i <= operatorIndices.length; i++) {
        if (i === 0) {
          const variable = formula.slice(0, operatorIndices[i]);
          formulaVariables.push(variable);
        } else if (i === operatorIndices.length) {
          const variable = formula.slice(operatorIndices[i - 1] + 1);
          formulaVariables.push(variable);
        } else {
          const variable = formula.slice(operatorIndices[i - 1] + 1, operatorIndices[i]);
          formulaVariables.push(variable);
        }
      }

      let result = parseFloat(formulaVariables[0]);
      for (let i = 0; i < formulaVariables.length - 1; i++) {
        if (formula[operatorIndices[i]] === "+") result = result + parseFloat(formulaVariables[i + 1]);
        else result = result - parseFloat(formulaVariables[i + 1]);
      }

      return result
    }
    catch (err) {
      console.log("Error evaluating actual cost formula");
      return null
    }
  }
}

export const transporterCostReconciliations = new TransporterCostReconciliationManager("/api/outsourced-route-costs/", storeKeys.transporterCostReconciliations);