import { iDeliveryRoute } from "../types";
import Vue from "vue";
import Vuex from "vuex";
import clonedeep from "lodash.clonedeep";

Vue.use(Vuex);

const _store = new Vuex.Store({
  state: {
    selectedDeliveryRoute: null as null | iDeliveryRoute,
    selectedDeliveryReportDate: "",
  },
  mutations: {
    _setSelectedDeliveryRoute(state, route: iDeliveryRoute) {
      state.selectedDeliveryRoute = clonedeep(route);
    },
    _setSelectedDeliveryReportDate(state, date: string) {
      state.selectedDeliveryReportDate = clonedeep(date);
    },
  },
});

export default _store;

export const store = {
  getSelectedDeliveryRoute() {
    return clonedeep(_store.state.selectedDeliveryRoute);
  },
  getSelectedDeliveryReportDate() {
    return clonedeep(_store.state.selectedDeliveryReportDate);
  },
  setSelectedDeliveryRoute(route: iDeliveryRoute | null) {
    _store.commit("_setSelectedDeliveryRoute", route);
  },
  setSelectedDeliveryReportDate(date: string) {
    _store.commit("_setSelectedDeliveryReportDate", date);
  },
};
