
import Vue from "vue";
import { iUser, authRole } from "../types";
import { auth } from "../plugins/auth";
import router from "../router";

export default Vue.extend({
  data() {
    return {
      showMenu: true,
      showUserInfoDialog: false,
    };
  },
  computed: {
    showLogout(): boolean {
      return auth.isSignedIn();
    },
    user(): iUser | null {
      return auth.getCurrentUser();
    },
    groups(): any[] {
      const user = auth.getCurrentUser();
      if (!user) return [];
      const roles = user.roles;
      const groups = [
        {
          text: "Deliveries",
          active: true,
          links: [
            {
              text: "Stores",
              icon: "shopping_cart",
              route: "/stores",
              requiredRoles: [],
              forbiddenRoles: [authRole.FM_GUEST, authRole.GENERIC_USER],
            },
            {
              text: "Delivery Locations",
              icon: "map",
              route: "/delivery-locations",
              requiredRoles: [],
              forbiddenRoles: [authRole.FM_GUEST, authRole.GENERIC_USER],
            },
            {
              text: "Delivery Reports",
              icon: "local_shipping",
              route: "/delivery-reports",
              requiredRoles: [authRole.OUTBOUND_SCHEDULER, authRole.OUTBOUND_ADMIN],
            },
            {
              text: "Telemetry Reports",
              icon: "assignment",
              route: "/telemetry-reports",
              requiredRoles: [authRole.OUTBOUND_ADMIN, authRole.FM],
            },
            {
              text: "Scheduled Deliveries",
              icon: "calendar_today",
              route: "/schedules",
              requiredRoles: [authRole.OUTBOUND_SCHEDULER, authRole.OUTBOUND_ADMIN, authRole.GENERIC_USER],
            },
          ],
        },
        {
          text: "Transport",
          active: true,
          links: [
            {
              text: "Fleet Management",
              icon: "emoji_transportation",
              route: "/fleet-management",
              requiredRoles: [authRole.TRANSPORT_ADMIN, authRole.FM_GUEST],
            },
            {
              text: "Transporters",
              icon: "commute",
              route: "/transporters",
              requiredRoles: [authRole.TRANSPORT_ADMIN],
            },
            {
              text: "SPAR Drivers",
              icon: "perm_identity",
              route: "/drivers",
              requiredRoles: [
                authRole.TRANSPORT_ADMIN,
                authRole.FM,
                authRole.TRANSPORT_SCHEDULER,
                authRole.OUTBOUND_SCHEDULER,
                authRole.OUTBOUND_ADMIN,
              ],
            },
            {
              text: "Driver Schedule",
              icon: "alarm",
              route: "/driver-schedules",
              requiredRoles: [authRole.TRANSPORT_ADMIN, authRole.TRANSPORT_SCHEDULER],
            },
            {
              text: "Driver Reports",
              icon: "alarm_on",
              route: "/driver-reports",
              requiredRoles: [authRole.TRANSPORT_ADMIN, authRole.TRANSPORT_SCHEDULER],
            },
          ],
        },
        {
          text: "Assembly",
          active: true,
          links: [
            {
              text: "Pickers",
              icon: "emoji_events",
              route: "/pickers",
              requiredRoles: [authRole.ASSEMBLY_CLERK, authRole.ASSEMBLY_CONTROLLER, authRole.ASSEMBLY_ADMIN],
            },
            {
              text: "Picker Incentives",
              icon: "alarm_add",
              route: "/picker-incentives",
              requiredRoles: [authRole.ASSEMBLY_CLERK, authRole.ASSEMBLY_CONTROLLER, authRole.ASSEMBLY_ADMIN],
            },
          ],
        },
        {
          text: "Finance",
          active: true,
          links: [
            {
              text: "Tracking",
              icon: "attach_money",
              route: "/finance/tracking",
              requiredRoles: [authRole.WATSON_ADMIN],
            },
            {
              text: "Budget",
              icon: "account_balance",
              route: "/finance/budget",
              requiredRoles: [authRole.WATSON_ADMIN],
            },
          ],
        },
        {
          text: "Admin",
          active: true,
          links: [
            { text: "Alerts", icon: "warning", route: "/alerts", requiredRoles: [authRole.WATSON_ADMIN] },
            { text: "Access", icon: "people", route: "/access", requiredRoles: [authRole.WATSON_ADMIN] },
            {
              text: "Sms Statuses",
              icon: "sms",
              route: "/sms-statuses",
              requiredRoles: [authRole.WATSON_ADMIN, authRole.TRANSPORT_ADMIN],
            },
          ],
        },
      ];

      return groups
        .map((group) => {
          group.links = group.links
            .map((x: any) => {
              if (!x.requiredRoles) x.requiredRoles = [];
              if (!x.forbiddenRoles) x.forbiddenRoles = [];
              return x;
            })
            .filter((x) => auth.hasRole(x.requiredRoles))
            .filter((x) => auth.missingRole(x.forbiddenRoles));
          return group;
        })
        .filter((group) => group.links.length !== 0);
    },
  },
  methods: {
    toggleMenuSize() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.showUserInfoDialog = false;
      auth.signOut();
      this.$router.push("/");
    },
  },
  watch: {
    $route(to: any) {
      if (to.meta?.closeSideMenu) this.showMenu = false;
      else this.showMenu = true;
    },
  },
  beforeMount() {
    if (router.currentRoute.meta?.closeSideMenu) this.showMenu = false;
    else this.showMenu = true;
  },
});
