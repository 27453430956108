import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { localToUtc, utcToLocal } from '../utils/time';
import axios from 'axios';


export enum cpkLevel {
  CPK_SINGLE = "CPK_SINGLE",
  FIXED = "FIXED",
  CPK_LONG = "CPK_LONG",
  CPK_SHORT = "CPK_SHORT",
  CPK_ONE_WAY = "CPK_ONE_WAY",
  CPK_TWO_WAY = "CPK_TWO_WAY",
}

export function cpkLevelDisplay(level: cpkLevel) {
  switch (level) {
    case cpkLevel.CPK_SINGLE: return "Single CPK for Transporter"
    case cpkLevel.FIXED: return "Fixed price"
    case cpkLevel.CPK_LONG: return "Long haul CPK"
    case cpkLevel.CPK_SHORT: return "Short haul CPK"
    case cpkLevel.CPK_ONE_WAY: return "One way CPK"
    case cpkLevel.CPK_TWO_WAY: return "Two way CPK"
  }
}

export interface iTransporterBaseCpk {
  id: number;
  startDate: string;
  endDate: string | null;
  transporterId: number;
  costPerKm: number;
  level: cpkLevel | null;
  active: boolean;
  createdUtc: string;
  confirmedUtc: string | null;
}

export interface iTransporterBaseCpkCreate {
  date: string;
  transporterId: number;
  costPerKm: number;
  level?: cpkLevel;
}

export interface iTransporterBaseCpkUpdate {
  costPerKm?: number;
  level?: cpkLevel;
  active?: boolean;
}

export interface iTransporterBaseCpkBulkUpdate {
  ids: number[]
  costPerKm?: number;
  level?: cpkLevel;
  active?: boolean;
}

class TransporterBaseCpksManager extends BaseManager<iTransporterBaseCpk, iTransporterBaseCpkCreate, iTransporterBaseCpkUpdate> {

  fetchForDate(date: string) {
    return this.fetch(`${this.baseUrl}${date}/`)
  }

  fetchForDateIfEmpty(date: string) {
    if (dataStore.getAll(this.storeKey).length == 0) {
      this.fetchForDate(date);
    }
  }

  transformInbound(data: any) {
    if (data.confirmedUtc) data.confirmedUtc = utcToLocal(data.confirmedUtc);
    // if (data.level) data.level = cpkLevel[data.level as keyof typeof cpkLevel];
    return data
  }

  bulkUpdate(updateDate: iTransporterBaseCpkBulkUpdate) {
    return axios.patch(`${this.baseUrl}bulk-update/`, updateDate).then((res) => {
      if (!res) return
      dataStore.upsert(this.storeKey, res.data.map((x: any) => this.transformInbound(x)));
    });
  }

  confirmBaseCpk(selectedCpk: iTransporterBaseCpk[]) {
    const ids = selectedCpk.map(x => x.id)
    return axios.patch(`${this.baseUrl}confirm/`, { ids: ids }).then((res) => {
      if (!res) return
      dataStore.upsert(this.storeKey, res.data.map((x: any) => this.transformInbound(x)));
    });
  }
}
export const transporterBaseCpks = new TransporterBaseCpksManager("/api/transporter-base-cpks/", storeKeys.transporterBaseCpks);
