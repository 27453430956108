
import Vue from "vue";
import { flash, iNotification, iNotificationLevel } from "../plugins/flashNotifications";

export default Vue.extend({
  name: "Notifications",
  computed: {
    notifications(): iNotification[] {
      return flash.getAll();
    },
  },
  methods: {
    close(notification: iNotification) {
      flash.clear(notification);
    },
  },
});
