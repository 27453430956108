import { dataStore, storeKeys } from "../plugins/dataStore";
import axios from 'axios';
import { iTelemetryReport } from '../types';
import { utcToLocal } from '../utils/time';


class TelemetryManager {
  fetch(date: string) {
    return axios.get(`/api/telemetry/${date}/`).then((res) => {
      const reports: iTelemetryReport[] = res.data.map((x: iTelemetryReport) => {
        const telemetryReport: iTelemetryReport = {
          route: x.route,
          trailerCode: x.trailerCode,
          fmProvider: x.fmProvider,
          fmProviderId: x.fmProviderId,
          distanceKm: x.distanceKm,
          driverId: x.driverId,
          driverName: x.driverName,
          departedDcUtc: utcToLocal(x.departedDcUtc),
          returnedDcUtc: utcToLocal(x.returnedDcUtc),
          deliveryLocation: x.deliveryLocation,
          arrivedUtc: utcToLocal(x.arrivedUtc),
          departedUtc: utcToLocal(x.departedUtc),
          startedUtc: utcToLocal(x.startedUtc),
          endedUtc: utcToLocal(x.endedUtc),
        };
        return telemetryReport;
      });
      dataStore.replace(storeKeys.telemetryReports, reports);
    });
  }
}

export const telemetry = new TelemetryManager();
