import axios from "axios";
import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { iDriver } from '../types';
import { localToUtc, utcToLocal } from '../utils/time';

export interface iDriverCreate {
  name: string;
  staffId?: number;
  cellNumber: string;
  pdpExpiryDate?: string;
}

export interface iDriverUpdate {
  name?: string;
  staffId?: number | null;
  cellNumber?: string;
  active?: boolean;
  pdpExpiryDate?: string | null;
  temporaryPdpActive?: boolean
}

class DriverManager extends BaseManager<iDriver, iDriverCreate, iDriverUpdate> {
  fetch(url = this.baseUrl) {
    return axios.get(url).then((res) => {
      if (!res?.data?.drivers) return
      dataStore.replace(this.storeKey, res.data.drivers.map((x: any) => this.transformInbound(x)));
    });
  }
  fetchDeactivated(deactivated: boolean) {
    return this.fetch(`${this.baseUrl}?deactivated=${deactivated}`)
  }
}

export const drivers = new DriverManager("/api/drivers/", storeKeys.drivers);
