
import Vue from "vue";
import { mgr } from "../../managers";
import { dataStore, storeKeys } from "../../plugins/dataStore";
import { validation } from "../../mixins/Validation";
import { flash } from "../../plugins/flashNotifications";
import clonedeep from "lodash.clonedeep";

import { iDeliveryLocation, iStore, storeType } from "../../types";

export default Vue.extend({
  name: "StoresUpdate",
  components: {},
  props: {
    type: { type: String as () => "Store" | "Supplier", default: "Store" },
    item: { type: Object, required: true },
  },
  data() {
    return {
      live: {},
      isValidForm: false,
    };
  },
  computed: {
    storeTypeOptions() {
      return Object.values(storeType);
    },
    deliveryLocationIdOptions() {
      const deliveryLocations = dataStore.getAll(storeKeys.deliveryLocations) as iDeliveryLocation[];
      const locations = deliveryLocations
        // .filter((x) => x.suppliers.length == 0)
        .map((x) => {
          return { text: x.name, value: x.id };
        });
      locations.push({ text: "None", value: -1 });
      return locations;
    },
  },
  methods: {
    ...validation,
    close() {
      this.$emit("close");
    },
    save() {
      if (!(this.$refs["update-form"] as any).validate()) return;
      const live: any = Object.assign({}, this.live);
      const item: iStore = this.item;

      const patchData: any = {};
      if (live.name && live.name !== item.name) patchData.name = live.name;
      if (live.storeType && live.storeType !== item.storeType) patchData.storeType = live.storeType;
      if (live.supplierNumber && live.supplierNumber !== item.supplierNumber)
        patchData.supplierNumber = live.supplierNumber;
      if (live.deliveryLocationId && live.deliveryLocationId !== item.deliveryLocationId)
        patchData.deliveryLocationId = live.deliveryLocationId;
      if (live.rom && live.rom !== item.rom) patchData.rom = live.rom;

      if (patchData.deliveryLocationId == -1) {
        delete patchData.deliveryLocationId;
        patchData.delinkLocation = true;
      }

      if (Object.keys(patchData).length == 0) {
        flash.success("No changes detected");
        this.close();
        return;
      }

      mgr.stores.update(this.item.id, patchData).then(() => {
        flash.success(`${this.type} successfully updated`);
        this.reset();
      });

      this.close();
    },
    reset() {
      (this.$refs["update-form"] as any).reset();
      this.live = {};
    },
    resetToOriginal() {
      this.live = clonedeep(this.item);
    },
  },
  watch: {
    item(item) {
      this.live = clonedeep(this.item);
    },
  },
  beforeMount() {
    this.live = clonedeep(this.item);
    mgr.stores.fetch();
  },
});
