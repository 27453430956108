import { VueConstructor } from "vue/types/umd";

// Show flash notifications to the user

/*
    To use, in main.ts add:
    import store from "./store";
    import flashNotifications from "./plugins/flashNotifications";
    Vue.use(flashNotifications, { store });

    // In a component
    import { flash } from "../plugins/flashNotifications";
    flash.info("Your msg!")
*/

export interface iNotification {
  id: number;
  level: iNotificationLevel;
  message: string;
  group: string;
}

export enum iNotificationLevel {
  info = "info",
  success = "success",
  warning = "warning",
  error = "error",
}

interface iNotificationState {
  notifications: iNotification[];
  nextId: number;
}

const CONTEXT = {
  store: {} as any, // The vuex store instance after installation
  displayTime: 6 * 1000, // 6sec
};

export default {
  install(Vue: VueConstructor<Vue>, { store }: { store: any }) {
    if (!store) throw new Error("Please provide vuex store.");
    CONTEXT.store = store;

    const state: iNotificationState = {
      notifications: [],
      nextId: 0,
    };

    const mutations = {
      _addNotification(state: iNotificationState, notification: iNotification) {
        // Increment the "nextId" counter and add the notification
        state.nextId = state.nextId + 1;
        Vue.set(state.notifications, state.notifications.length, notification);
      },
      _removeNotification(state: iNotificationState, notification: iNotification) {
        const index = state.notifications.findIndex((x: iNotification) => x.id === notification.id);
        if (index >= 0) {
          state.notifications.splice(index, 1);
        }
      },
    };

    const actions = {};

    CONTEXT.store.registerModule("flashNotifications", {
      state,
      mutations,
      actions,
    });
  },
};

export const flash = {
  info(message: string, group = ""): void {
    addNotification({
      id: CONTEXT.store.state.flashNotifications.nextId,
      level: iNotificationLevel.info,
      message,
      group,
    });
  },
  success(message: string, group = ""): void {
    addNotification({
      id: CONTEXT.store.state.flashNotifications.nextId,
      level: iNotificationLevel.success,
      message,
      group,
    });
  },
  warning(message: string, group = ""): void {
    addNotification({
      id: CONTEXT.store.state.flashNotifications.nextId,
      level: iNotificationLevel.warning,
      message,
      group,
    });
  },
  error(message: string, group = ""): void {
    addNotification({
      id: CONTEXT.store.state.flashNotifications.nextId,
      level: iNotificationLevel.error,
      message,
      group,
    });
  },
  getAll(): iNotification[] {
    return CONTEXT.store.state.flashNotifications.notifications;
  },
  clear(notification: iNotification): void {
    CONTEXT.store.commit("_removeNotification", notification);
  },
  clearGroup(group: string): void {
    const notifications: iNotification[] = CONTEXT.store.state.flashNotifications.notifications;
    notifications.forEach((notification: iNotification) => {
      if (notification.group === group) CONTEXT.store.commit("_removeNotification", notification);
    });
  },
  clearAll(): void {
    const notifications: iNotification[] = CONTEXT.store.state.flashNotifications.notifications;
    notifications.forEach((notification: iNotification) => {
      CONTEXT.store.commit("_removeNotification", notification);
    });
  },
};

function addNotification(notification: iNotification) {
  flash.clearAll();
  CONTEXT.store.commit("_addNotification", notification);
  // Set a timer so to remove that notification
  setTimeout(() => {
    CONTEXT.store.commit("_removeNotification", notification);
  }, CONTEXT.displayTime);
}
