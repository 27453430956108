import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { iOffloadContainerGroup } from '../types';
import axios from "axios"

export interface iOffloadContainerGroupCreate {
  offloadEventId: number;
  storeCode?: number;
  supplierNumber?: number;
  totalContainers: number;
}

export interface iOffloadContainerGroupUpdate {
  offloadEventId?: number;
  storeCode?: number;
  supplierNumber?: number;
  totalContainers?: number;
}

class OffloadContainerGroupManager extends BaseManager<iOffloadContainerGroup, iOffloadContainerGroupCreate, iOffloadContainerGroupUpdate> {

  update(id: number, data: iOffloadContainerGroupUpdate, merge = false, url = `${this.baseUrl}${id}/`) {
    return axios.patch(url, this.transformOutbound(data)).then((res) => {
      if (!res) return
      res.data = this.transformInbound(res.data)
      dataStore.upsert(storeKeys.offloadContainerGroups, [res.data]);
      dataStore.upsert(storeKeys.offloadContainerGroupsInitial, [res.data]);
      dataStore.remove(storeKeys.offloadContainerGroupsEdits, [res.data]);
    });
  }
}

export const offloadContainerGroups = new OffloadContainerGroupManager("/api/offload-container-groups/", storeKeys.offloadContainerGroups);
