import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { iUser, authRole } from '../types';
import axios from 'axios';
import { localToUtc, utcToLocal } from '../utils/time';

export interface iUserCreate {
  email: string;
  username: string;
  organisationId: string;
  roles: authRole[];
}

export interface iUserUpdate {
  email?: string;
  password?: string;
  username?: string;
  active?: boolean;
  organisationId?: string;
  roles?: authRole[];
}

class UsersManager extends BaseManager<iUser, iUserCreate, iUserUpdate> {
  transformInbound(data: any) {
    if (!data) return data
    if (data.lastActiveDate) data.lastActiveDate = utcToLocal(data.lastActiveDate);
    return data
  }

  transformOutbound(data: any) {
    if (data.lastActiveDate) data.lastActiveDate = localToUtc(data.lastActiveDate);
    return data
  }

  fetch(url = this.baseUrl) {
    return axios.get(url).then((res) => {
      if (!res) return
      dataStore.replace(this.storeKey, res.data
        .map((x: any) => this.transformInbound(x))
        .sort((a: iUser, b: iUser) => a.username.localeCompare(b.username)));
    });
  }

  register(password: string, token: string) {
    return axios.patch(`${this.baseUrl}register/`, { emailToken: token, password: password }).then((res) => {
      dataStore.replace(storeKeys.users, [this.transformInbound(res.data)]);
    });
  }

  requestConfirmationEmail(email: string) {
    return axios.post(`${this.baseUrl}send-confirmation-email/`, { email: email });
  }

  resendInvitationEmail(userId: number) {
    return axios.post(`${this.baseUrl}resend-invitation-email/`, { id: userId });
  }

  sendPasswordResetEmail(email: string) {
    return axios.post(`${this.baseUrl}send-password-reset/`, { email: email });
  }

  resetPassword(email: string, password: string, token: string) {
    return axios.post(`${this.baseUrl}reset-password/`, { email, password, token })
  }

}

export const users = new UsersManager("/api/users/", storeKeys.users);
