import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { iPickerPenaltySimple, iPickerPenaltyDetailed } from '../types';
import axios from "axios";


export interface iPickerPenaltySimpleCreate {
  pickerId: number
  pickingPeriod: string
  penaltyTypeId: number
  numPenalties: number
}

export interface iPickerPenaltySimpleUpdate {
  pickerId?: number
  pickingPeriod?: string
  penaltyTypeId?: number
  numPenalties?: number
}

export interface iPickerPenaltyDetailedCreate {
  pickerId: number
  pickingPeriod: string
  penaltyTypeId: number
  date: string
  assignmentNumber: number
  storeCode: number
  claimNumber?: number
  invoiceNumber?: number
  itemCode: number
  numberOfCases: number
  value: number
  weight?: number
}

export interface iPickerPenaltyDetailedUpdate {
  pickerId?: number
  pickingPeriod?: string
  penaltyTypeId?: number
  date?: string
  assignmentNumber?: number
  storeCode?: number
  claimNumber?: number | null
  invoiceNumber?: number | null
  itemCode?: number
  numberOfCases?: number
  value?: number
  weight?: number
}

// export enum pickerReportType {
//   dry = "dry",
//   perish = "perish",
// }

function transformEitherInbound(data: any) {
  // Could be simple, detailed or overtime
  return data
}

class SimpleManager extends BaseManager<iPickerPenaltySimple, iPickerPenaltySimpleCreate, iPickerPenaltySimpleUpdate> { }
class DetailedManager extends BaseManager<iPickerPenaltyDetailed, iPickerPenaltyDetailedCreate, iPickerPenaltyDetailedUpdate> { }

export const pickerPenalties = {
  getAnyTypeInPickingPeriod: (year: number, month: number) => {
    return axios.get(`/api/picker-penalties/${year}-${month}-01/`).then((res) => {
      if (!res) return
      dataStore.replace(storeKeys.pickerPenaltiesSimple, res.data.simple.map((x: any) => transformEitherInbound(x)));
      dataStore.replace(storeKeys.pickerPenaltiesDetailed, res.data.detailed.map((x: any) => transformEitherInbound(x)));
    });
  },

  downloadReport(reportType: string, warehouseType: string, picking_period: string,) {
    if (picking_period.length <= 7) picking_period += "-01"
    return axios.get(`/api/picker-penalties/download/?picking_period=${picking_period}&report_type=${reportType}&warehouse_type=${warehouseType}`)
  },

  submit(picking_period: string,) {
    if (picking_period.length <= 7) picking_period += "-01"
    return axios.patch(`/api/picker-penalties/submit/${picking_period}/`).then((res) => {
      if (!res) return
      dataStore.replace(storeKeys.pickerPenaltiesSimple, res.data.simple.map((x: any) => transformEitherInbound(x)));
      dataStore.replace(storeKeys.pickerPenaltiesDetailed, res.data.detailed.map((x: any) => transformEitherInbound(x)));
      dataStore.replace(storeKeys.pickerOvertime, res.data.overtime.map((x: any) => transformEitherInbound(x)));
    });
  },

  unSubmit(picking_period: string,) {
    if (picking_period.length <= 7) picking_period += "-01"
    return axios.patch(`/api/picker-penalties/un-submit/${picking_period}/`).then((res) => {
      if (!res) return
      dataStore.replace(storeKeys.pickerPenaltiesSimple, res.data.simple.map((x: any) => transformEitherInbound(x)));
      dataStore.replace(storeKeys.pickerPenaltiesDetailed, res.data.detailed.map((x: any) => transformEitherInbound(x)));
      dataStore.replace(storeKeys.pickerOvertime, res.data.overtime.map((x: any) => transformEitherInbound(x)));
    });
  },

  simple: new SimpleManager("/api/picker-penalties/simple/", storeKeys.pickerPenaltiesSimple),
  detailed: new DetailedManager("/api/picker-penalties/detailed/", storeKeys.pickerPenaltiesDetailed),
}
