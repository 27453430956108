import { iIssueGroup, iOffloadContainerGroup, iOffloadEvent } from "../types";
import moment from "moment";
import { multiPush } from "./issues"
import { mgr } from ".";
import { formatSeconds } from "../utils/time";

export const offloadEvents = {
  validate(x: iOffloadEvent): iIssueGroup {
    const group: iIssueGroup = { missing: [], errors: [], warnings: [] }
    multiPush(this, group, x, "offloadEvent", "startedUtc")
    multiPush(this, group, x, "offloadEvent", "endedUtc")
    return group
  },
  startedUtc: {
    missing(x: iOffloadEvent) { return !x.startedUtc ? "Missing" : null },
    errors(x: iOffloadEvent) {
      const deliveryStop = mgr.deliveryStops.getById(x.deliveryStopId)
      const offloadEvents = mgr.offloadEvents.getAll().filter(a => a.deliveryStopId == x.deliveryStopId)
      if (!deliveryStop) return null
      if (moment(x.startedUtc).isBefore(deliveryStop.arrivedUtc)) return "Started before Arrived";
      if (moment(x.startedUtc).isSameOrAfter(x.endedUtc)) return "Started after Ended";
      if (offloadEvents.length <= 1) return null;
      // The next cant Start before the current one ends
      const checkIfDuplicateExists = (arr: any[]) => { return new Set(arr).size !== arr.length; }
      if (checkIfDuplicateExists(offloadEvents.map((x: iOffloadEvent) => x.startedUtc)))
        return "Can't start and the same time";
      return null
    },
    missingOrErrors(x: iOffloadEvent) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    },
  },
  endedUtc: {
    missing(x: iOffloadEvent) { return !x.endedUtc ? "Missing" : null },
    errors(x: iOffloadEvent) {
      const deliveryStop = mgr.deliveryStops.getById(x.deliveryStopId)
      const offloadEvents = mgr.offloadEvents.getAll().filter(a => a.deliveryStopId == x.deliveryStopId)

      if (!deliveryStop) return null
      if (moment(x.endedUtc).isSameOrBefore(x.startedUtc))
        return "Ended before started";
      if (moment(deliveryStop.departedUtc).isBefore(x.endedUtc)) return "Departed before ended";
      // Cant have two start at the same time
      const eventsInStartOrder: iOffloadEvent[] = offloadEvents.sort((a: iOffloadEvent, b: iOffloadEvent) =>
        moment(a.startedUtc).isSameOrAfter(b.startedUtc) ? 1 : moment(b.startedUtc).isSameOrAfter(a.startedUtc) ? -1 : 0
      );
      const index = eventsInStartOrder.findIndex((x: iOffloadEvent) => x.id === x.id);
      if (index === -1 || index + 1 >= eventsInStartOrder.length) return null;
      const nextEvent = eventsInStartOrder[index + 1];
      if (moment(nextEvent.startedUtc).isSameOrBefore(x.endedUtc))
        return "Next Started before this Ended";
      return null
    },
    warnings(x: iOffloadEvent) {
      const containersForEvent = mgr.offloadContainerGroups.getAll().filter(a => a.offloadEventId === x.id);
      if (offloadDurationIsExtreme(x, containersForEvent)) {
        const seconds = offloadRate(x, containersForEvent)
        if (Number.isNaN(seconds)) return null
        return `${formatSeconds(seconds)}/containers`
      }
      return null;
    },
    missingOrErrors(x: iOffloadEvent) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    },
  },
}



function offloadDuration(offloadEvent: iOffloadEvent) {
  // How long the offload event was in seconds
  const started = moment(offloadEvent.startedUtc);
  const ended = moment(offloadEvent.endedUtc);
  return ended.diff(started, "seconds");
}

function offloadRate(offloadEvent: iOffloadEvent, offloadContainerGroups: iOffloadContainerGroup[]) {
  // Seconds per container
  const totalContainers = offloadContainerGroups.reduce((sum, x) => sum + x.totalContainers, 0);
  return offloadDuration(offloadEvent) / totalContainers;
}

function offloadDurationIsExtreme(offloadEvent: iOffloadEvent, offloadContainerGroups: iOffloadContainerGroup[]): boolean {
  const secPerContainer = offloadRate(offloadEvent, offloadContainerGroups);
  if (secPerContainer < 10 || secPerContainer > 10 * 60) return true;
  return false;
}