import { BaseManager } from "./baseManager";
import { storeKeys } from "../plugins/dataStore";
import { iPickerIncentiveBand } from '../types';

export interface iPickerIncentiveBandCreate {
  startDate: string;
  endDate?: string;
  bandLowerBound: number;
  bandUpperBound: number;
  band: number;
  rate: number;
  departmentId: number;
}

export interface iPickerIncentiveBandUpdate {
  startDate?: string;
  endDate?: string | null;
  bandLowerBound?: number;
  bandUpperBound?: number;
  band?: number;
  rate?: number;
  departmentId?: number;
}

class PickerIncentiveBandsManager extends BaseManager<iPickerIncentiveBand, iPickerIncentiveBandCreate, iPickerIncentiveBandUpdate> { }

export const pickerIncentiveBands = new PickerIncentiveBandsManager("/api/picker-incentive-bands/", storeKeys.pickerIncentiveBands);
