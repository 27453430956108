
import Vue from "vue";
import { iUser, authRole } from "../types";
import { auth } from "../plugins/auth";
import { validation } from "../mixins/Validation";
import { mgr } from "../managers";
import { flash } from "../plugins/flashNotifications";

export default Vue.extend({
  data() {
    return {
      isValidForm: false,
      isValidResendForm: false,
      email: "",
      password: "",
      resendEmail: "",
      showPassword: false,
      showUnconfirmed: false,
      showForgotPassword: false,
      isValidForgotForm: false,
      forgotPasswordEmail: "",
    };
  },
  computed: {
    passwordType(): string {
      return this.showPassword ? "text" : "password";
    },
    user(): iUser | null {
      return auth.getCurrentUser();
    },
  },
  methods: {
    ...validation,
    doLogin() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        auth.signIn(this.email, this.password).then((msg) => {
          if (msg && msg === "Unconfirmed email") this.showUnconfirmed = true;
        });
      }
    },
    resend() {
      if (!(this.$refs["resend-form"] as any).validate()) return;
      mgr.users.requestConfirmationEmail(this.resendEmail).then(() => {
        flash.success("Verification email sent");
        this.showUnconfirmed = false;
      });
    },
    sendForgotPasswordEmail() {
      if (!(this.$refs["forgot-form"] as any).validate()) return;
      flash.info("Sending...");
      this.showForgotPassword = false;
      mgr.users.sendPasswordResetEmail(this.forgotPasswordEmail).then(() => {
        flash.success("Password reset email sent");
      });
    },
  },
  beforeMount() {
    // Redirect if signed in
    if (auth.isSignedIn()) {
      const user = auth.getCurrentUser();
      if (user && auth.hasRole([authRole.OUTBOUND_ADMIN])) this.$router.push("/alerts");
      if (user && auth.hasRole([authRole.GENERIC_USER])) this.$router.push("/schedules");
      else this.$router.push("/stores");
    }
  },
});
