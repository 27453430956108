import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import auth from "./plugins/auth";
import flashNotifications from "./plugins/flashNotifications";
import dataStore from "./plugins/dataStore";

import "material-design-icons-iconfont/dist/material-design-icons.css";


// Sentry
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://413e511333ca40a3a7ba85a7b83ff308@o597990.ingest.sentry.io/5744104",
    ignoreErrors: [
      'Error: Request failed with status code 401',
      'Error: Request failed with status code 403',
      'Error: Request failed with status code 409',
      /IntegrityError: \(psycopg2\.errors\.UniqueViolation\) duplicate key value violates unique constraint/,
    ],
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    logErrors: true
  });
}

// Leaflet maps setup
// https://korigan.github.io/Vue2Leaflet/#/quickstart.md
import { Icon } from "leaflet";
let x: any = Icon.Default;
delete x.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Install plugins
Vue.use(flashNotifications, { store });
Vue.use(dataStore, { store });
Vue.use(auth, { store, router });

// Add focus directive
Vue.directive("focus", {
  inserted: function (el) {
    el.focus();
  },
});

// Vue setup
Vue.config.productionTip = false;

// Suppress warning TODO: Remove
// https://github.com/vuetifyjs/vuetify/issues/9999
const ignoreWarnMessage = "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function (msg: any, vm: any, trace: any) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
