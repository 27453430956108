import { BaseManager } from "./baseManager";
import { storeKeys } from "../plugins/dataStore";
import { iOrganisation } from '../types';

export interface iOrganisationCreate {
  name: string;
}

export interface iOrganisationUpdate {
  name?: string;
  active?: boolean;
}

class OrganisationManager extends BaseManager<iOrganisation, iOrganisationCreate, iOrganisationUpdate> { }

export const organisations = new OrganisationManager("/api/organisations/", storeKeys.organisations);
