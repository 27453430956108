import { BaseManager } from "./baseManager";
import { storeKeys } from "../plugins/dataStore";
import { iDepartment } from '../types';

export interface iDepartmentCreate {
  id: number;
  name: string;
}

export interface iDepartmentUpdate {
  id?: number;
  name?: string;
}

class DepartmentManager extends BaseManager<iDepartment, iDepartmentCreate, iDepartmentUpdate> { }

export const departments = new DepartmentManager("/api/departments/", storeKeys.departments);
