import { BaseManager } from "./baseManager";
import { storeKeys, dataStore } from "../plugins/dataStore";
import { authRole, iApikey } from '../types';
import { localToUtc, utcToLocal } from '../utils/time';
import axios from "axios"

export interface iApikeyCreate {
  userId: number;
  roles: authRole[];
}

export interface iApikeyUpdate {
  active?: boolean;
  roles?: authRole[];
}

class ApikeyManager extends BaseManager<iApikey, iApikeyCreate, iApikeyUpdate> {
  transformInbound(data: any) {
    if (!data) return data
    if (data.lastActiveDate) data.lastActiveDate = utcToLocal(data.lastActiveDate);
    if (data.createdDate) data.createdDate = utcToLocal(data.createdDate);
    return data
  }

  transformOutbound(data: any) {
    if (data.lastActiveDate) data.lastActiveDate = localToUtc(data.lastActiveDate);
    if (data.createdDate) data.createdDate = localToUtc(data.createdDate);
    return data
  }

  create(data: iApikeyCreate) {
    return axios.post(this.baseUrl, this.transformOutbound(data)).then((res) => {
      dataStore.upsert(this.storeKey, [this.transformInbound(res.data.apikey)]);
      return res.data.key
    });
  }

  createForSelf() {
    return axios.post("/api/apikeys/self/").then((res) => {
      return { key: res.data.key, apikey: this.transformInbound(res.data.apikey) }
    });
  }

  invalidate(apikeyId: number) {
    return axios.patch(`/api/apikeys/invalidate/${apikeyId}/`)
  }

  invalidateForSelf() {
    return axios.patch("/api/apikeys/invalidate/")
  }

  refresh(apikeyId: number) {
    return axios.patch(`/api/apikeys/refresh/${apikeyId}/`).then((res) => {
      if (!res) return ""
      const apikey = this.transformInbound(res.data.apikey)
      dataStore.upsert(storeKeys.apikeys, [this.transformInbound(res.data.apikey)]);
      return res.data.key
    });;
  }

  getCurrentApikey(): Promise<iApikey | null> {
    return axios
      .get("/api/auth/current-apikey/")
      .then((res) => {
        // if (!res) return null
        return this.transformInbound(res.data) as iApikey
      })
  }
}

export const apikeys = new ApikeyManager("/api/apikeys/", storeKeys.apikeys);
