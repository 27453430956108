import { BaseManager } from "./baseManager";
import { storeKeys } from "../plugins/dataStore";
import { iFmProvider } from '../types';

export interface iFmProviderCreate {
  name: string;
}

export interface iFmProviderUpdate {
  name?: string;
}

class FmProvidersManager extends BaseManager<iFmProvider, iFmProviderCreate, iFmProviderUpdate> { }

export const fmProviders = new FmProvidersManager("/api/fm-providers/", storeKeys.fmProviders);
