import { BaseManager } from "./baseManager";
import { storeKeys } from "../plugins/dataStore";
import { iPickerOvertime } from '../types';

export interface iPickerOvertimeCreate {
  pickerId: number
  pickingPeriod: string
  overtimeTypeId: number
  hours: number
}

export interface iPickerOvertimeUpdate {
  pickerId?: number
  pickingPeriod?: string
  overtimeTypeId?: number
  hours?: number
}

class PickerOvertimeManager extends BaseManager<iPickerOvertime, iPickerOvertimeCreate, iPickerOvertimeUpdate> {
  getForPickingPeriod(year: number, month: number) {
    return this.fetch(`${this.baseUrl}${year}-${month}-01/`)
  }
}

export const pickerOvertime = new PickerOvertimeManager("/api/picker-overtime/", storeKeys.pickerOvertime);
