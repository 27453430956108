import { BaseManager } from "./baseManager";

import { iDeliveryRoute } from '../types';
import { dataStore, storeKeys } from "../plugins/dataStore";
import axios from "axios"
import { localToUtc, utcToLocal } from '../utils/time';



export interface iDeliveryRouteCreate {
  routeDate: string;
  route: number;
  trailerCode: string;
  driverId: string;
  // driverName: string;
  scheduledDepartureUtc?: string;
  departedUtc: string;
  returnedUtc?: string;
  distanceKm?: number;
}

export interface iDeliveryRouteUpdate {
  routeDate?: string;
  route?: number;
  trailerCode?: string;
  driverId?: string | null;
  driverName?: string | null;
  outsourcedDriverName?: string | null;
  scheduledDepartureUtc?: string;
  departedUtc?: string;
  returnedUtc?: string;
  distanceKm?: number;
}

class DeliveryRouteManager extends BaseManager<iDeliveryRoute, iDeliveryRouteCreate, iDeliveryRouteUpdate> {
  transformInbound(data: any) {
    data.departedUtc = utcToLocal(data.departedUtc);
    data.returnedUtc = utcToLocal(data.returnedUtc);
    data.scheduledDepartureUtc = utcToLocal(data.scheduledDepartureUtc);

    return data as iDeliveryRoute
  }

  transformOutbound(data: any) {
    if (data.departedUtc) data.departedUtc = localToUtc(data.departedUtc);
    if (data.returnedUtc) data.returnedUtc = localToUtc(data.returnedUtc);
    if (data.scheduledDepartureUtc) data.scheduledDepartureUtc = localToUtc(data.scheduledDepartureUtc);
    return data
  }

  update(id: number, data: iDeliveryRouteUpdate, merge = false, url = `${this.baseUrl}${id}/`) {
    return axios.patch(url, this.transformOutbound(data)).then((res) => {
      if (!res) return
      res.data = this.transformInbound(res.data)
      dataStore.upsert(storeKeys.deliveryRoutes, [res.data]);
      dataStore.upsert(storeKeys.deliveryRoutesInitial, [res.data]);
      dataStore.remove(storeKeys.deliveryRoutesEdits, [res.data]);
    });
  }
}

export const deliveryRoutes = new DeliveryRouteManager("/api/delivery-routes/", storeKeys.deliveryRoutes);
