import axios from "axios";
import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { iStore, storeType } from '../types';

export interface iStoreCreate {
  code?: number;
  name: string;
  storeType?: storeType;
  supplierNumber?: number;
  rom?: string;
  active?: boolean;
  deliveryLocationId: number;
  isSupplier: boolean
}

export interface iStoreUpdate {
  name?: string;
  storeType?: storeType;
  supplierNumber?: number;
  rom?: string;
  active?: boolean;
  deliveryLocationId?: number;
  delinkLocation?: boolean;
}


class StoreManager extends BaseManager<iStore, iStoreCreate, iStoreUpdate> {
  fetch(url = this.baseUrl) {
    return axios.get(url).then((res) => {
      if (!res?.data?.stores) return
      dataStore.replace(this.storeKey, res.data.stores.map((x: any) => this.transformInbound(x)));
    });
  }

  fetchDeactivated(deactivated: boolean) {
    return this.fetch(`${this.baseUrl}?deactivated=${deactivated}`)
  }

  updateStoreCode(id: number, code: number) {
    return axios.patch(`${this.baseUrl}code/${id}/`, { code }).then((res) => {
      if (!res) return
      dataStore.upsert(this.storeKey, res.data.map((x: any) => this.transformInbound(x)));
    });
  }
}

export const stores = new StoreManager("/api/stores/", storeKeys.stores);
