
export function padPositionToSix(num: number): string {
  let str = num.toString();
  if (!str.includes(".")) str += ".000000";
  const arr = str.split(".");
  while (arr[1].length < 6) {
    arr[1] += "0";
  }
  return arr.join(".");
}

export function jsonToCsv(objs: object[]) {
  const separator = ",";
  const eol = "\n";
  const headings = Object.keys(objs[0]).map((x) => capitalize(x));
  let csv = headings.join(separator) + eol;
  objs.forEach((row: any) => {
    Object.values(row).forEach((val: any) => {
      if (Array.isArray(val)) csv += val.join(" ") + separator;
      else csv += val + separator;
    });
    csv += eol;
  });
  return csv;
}

export function downloadJsonAsCsv(objs: object[], fileName: string) {
  const csv = jsonToCsv(objs);
  const blob = new Blob([csv], {
    type: "text/csv;charset=utf-8;",
  });
  const link = document.createElement("a");
  link.setAttribute("href", URL.createObjectURL(blob));
  link.setAttribute("download", fileName);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function capitalize(text: string) {
  return text
    .split("")
    .map((char, i) => (i ? char : char.toUpperCase()))
    .join("");
}
