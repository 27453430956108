import axios from "axios";
import { VueConstructor } from "vue/types/umd";
import { VueRouter } from "vue-router/types/router";
import { flash } from "../plugins/flashNotifications";
import { iUser, authRole, iApikey } from "../types";
import router from '../router';
import dataStore from './dataStore';

// Manages user authentication and current user info

/*
    To use, in main.ts add:
    import store from "./store";
    import auth from "./plugins/auth";
    Vue.use(auth, { store, router });

        // In a component
    import { auth } from "../plugins/auth";
    auth.signIn("email", "password");
    auth.getCurrentUser();
*/

interface iAuthState {
  currentUser: iUser | null;
}

interface iAuthConfigOptions {
  store: any; // Vuex store
  router: VueRouter;
}

const CONTEXT = {
  store: {} as any, // The vuex store instance after installation
  localStorageTokenKey: "watsonHubToken",
};

export default {
  install(Vue: VueConstructor<Vue>, { store, router }: iAuthConfigOptions) {
    if (!store) throw new Error("Please provide vuex store.");
    CONTEXT.store = store;

    // Try set axios default header to include the authToken from localStorage
    const storedToken = localStorage.getItem(CONTEXT.localStorageTokenKey);
    if (storedToken) setAxiosAuthHeader(storedToken);

    // Configure axios
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (!error.response) return

        const method = error.response.config.method?.toUpperCase() || undefined
        const url = error.response.config.url || undefined
        const requestData = error.response.config.data || undefined
        const code = error.response.status;
        const data = error.response.data;

        // Log for sentry
        console.log(`${code} error on ${method}: ${url}`, requestData, data)

        // handel 401 Unauthorized responses for auth time out
        flash.clearAll();

        if (code === 401) {
          if (auth.isSignedIn()) auth.signOut();
        }
        if (data?.detail && data?.detail.length > 0) {
          // Pydantic error format
          if (Array.isArray(data.detail)) {
            const msgs: string[] = data.detail.map((x: any) => {
              return `${x.msg} [${x.loc[x.loc.length - 1]}]`
            });
            flash.error(`Error: ${msgs.join(", ")}`);
          }
          else flash.error(`${data.detail}`);
        }
        else if (data) {
          if (data.toString().startsWith("<html")) flash.error(`There has been an error`);
          else flash.error(data.toString());
        }

        // Don't reject some errors
        // if (code >= 400 && code < 500) return Promise.resolve();
        // else 
        return Promise.reject(error);

      }
    );

    // Route navigation guards
    router.beforeEach(async (to, from, next) => {
      let destination = "";
      let message = "";

      // If we have a token but not user, then try get user with token
      if (storedToken && !auth.getCurrentUser()) {
        await auth.loadCurrentUser();
      }

      // Set message and destinations
      // Note: Later destinations and messages overwrite earlier destinations and messages
      if (to.meta?.requiresAuth && !auth.isSignedIn()) {
        if (from.path !== "/")
          message = "Please sign in to continue";
        destination = "/";
      }

      if (to.meta?.requiresRoles && (!auth.hasRole(to.meta.requiresRoles) && !auth.isSuperuser())) {
        message = "You do not have permission to continue";
        destination = from.path;
      }

      if (message !== "") {
        flash.clearAll();
        flash.error(message);
      }
      if (destination !== "") next(destination);
      else next();
    });

    const state: iAuthState = {
      currentUser: null,
    };

    const mutations = {
      _setCurrentUser(state: iAuthState, currentUser: iUser) {
        state.currentUser = currentUser;
      },
      _removeCurrentUser(state: iAuthState) {
        localStorage.removeItem(CONTEXT.localStorageTokenKey);
        state.currentUser = null;
      },
    };

    const actions = {};
    store.registerModule("auth", { state, mutations, actions });
  },
};

export const auth = {
  signIn(email: string, password: string): Promise<void | string> {
    flash.info("Signing in...", "auth");
    return axios
      .post("api/auth/login/", { email: email, password: password })
      .then((res) => {
        const token = res.data.authToken;
        const user = res.data.user;
        localStorage.setItem(CONTEXT.localStorageTokenKey, token);
        setAxiosAuthHeader(token);
        CONTEXT.store.commit("_setCurrentUser", user);
        flash.clearAll();
        flash.success(`You are logged in as ${user.username}`, "auth");
        // Redirect to page
        if (user && auth.hasRole([authRole.OUTBOUND_ADMIN])) router.push("/alerts");
        if (user && auth.hasRole([authRole.GENERIC_USER])) router.push("/schedules");
        else router.push("/stores");
      })
      .catch((err) => {
        CONTEXT.store.commit("_removeCurrentUser");
        const msg = err.response?.data?.detail
        if (msg && typeof msg === "string") {
          if (["Incorrect", "Inactive", "Unconfirmed", "not found"].some(x => msg.includes(x))) flash.error(msg)
          return msg
        }
      });
  },
  signOut(): void {
    CONTEXT.store.commit("_removeCurrentUser");
    flash.clearAll();
    flash.success(`Successfully signed out`, "auth");
  },
  isSignedIn(): boolean {
    // Must have a user stored
    const currentUser = CONTEXT.store.state.auth.currentUser;
    if (!currentUser) return false;
    // Must have an auth token
    const authToken = localStorage.getItem(CONTEXT.localStorageTokenKey);
    if (!authToken) return false;
    // User must have an email
    if (currentUser?.email?.length > 0) return true;
    return false;
  },
  getCurrentUser(): iUser | null {
    return CONTEXT.store.state.auth.currentUser;
  },
  hasAllRoles(roles: authRole[]): boolean {
    // User must have all the roles to return true
    if (roles.length === 0) return true;
    const currentUser: iUser = CONTEXT.store.state.auth.currentUser;
    for (const required_role of roles) {
      if (!currentUser.roles.includes(required_role)) return false;
    }
    return true;
  },
  isSuperuser() {
    const currentUser: iUser = CONTEXT.store.state.auth.currentUser;
    if (currentUser?.superuser === true) return true;
    return false
  },
  hasRole(requiredRoles: authRole[]): boolean {
    // User must have at lease one of the roles to return true
    if (requiredRoles.length === 0) return true;
    const currentUser: iUser = CONTEXT.store.state.auth.currentUser;
    if (!currentUser || !currentUser.roles) return false;
    if (currentUser?.superuser === true) return true;
    for (const required_role of requiredRoles) {
      if (currentUser.roles.some((x: authRole) => x === required_role)) return true;
    }
    return false;
  },
  missingRole(forbiddenRoles: authRole[]): boolean {
    // User does not have any of these roles to return true
    if (forbiddenRoles.length === 0) return true;
    const currentUser: iUser = CONTEXT.store.state.auth.currentUser;
    if (currentUser?.superuser === true) return true;
    for (const forbidden_role of forbiddenRoles) {
      if (currentUser.roles.some((x: authRole) => x === forbidden_role)) return false;
    }
    return true;
  },
  loadCurrentUser() {
    return axios
      .get("/api/auth/current-user/")
      .then((res) => {
        CONTEXT.store.commit("_setCurrentUser", res.data);
      })
      .catch((err) => {
        CONTEXT.store.commit("_removeCurrentUser");
      });
  },
  confirmEmailAddress(tokenFromEmail: string) {
    return axios.post("/api/users/confirm-email/", {
      token: tokenFromEmail,
    });
  },
};

function setAxiosAuthHeader(token: string): void {
  axios.defaults.headers.common["Authorization"] = "Bearer" + " " + token;
}
