
import Vue from "vue";
import { mgr } from "../../managers";
import { authRole, iDeliveryLocation, iStore } from "../../types";
import { dataStore, storeKeys } from "../../plugins/dataStore";
import { flash } from "../../plugins/flashNotifications";

import StoresCreate from "./StoresCreate.vue";
import StoresUpdate from "./StoresUpdate.vue";
// import StoresUpdateCode from "./StoresUpdateCode.vue";
import ConfirmationCard from "../ConfirmationCard.vue";
import { auth } from "../../plugins/auth";
import { downloadJsonAsCsv } from "../../utils/general";

export default Vue.extend({
  name: "StoreViewer",
  components: {
    StoresCreate,
    StoresUpdate,
    ConfirmationCard,
    StoresUpdateCode: () => import("./StoresUpdateCode.vue"),
  },
  props: {
    type: { type: String as () => "Store" | "Supplier", default: "Store" },
  },
  data() {
    return {
      searchText: "",
      includeDeactivated: false,
      showCreateDialog: false,
      showUpdateDialog: false,
      showReactivateDialog: false,
      showDeactivateDialog: false,
      itemToEdit: undefined as undefined | iStore,
      itemToReactivate: undefined as undefined | iStore,
      itemToDeactivate: undefined as undefined | iStore,
      storeCodeEditItem: null as null | iStore,
      busyExporting: false,
      loading: true,
    };
  },
  computed: {
    formattedTableData() {
      let stores = dataStore.getAll(storeKeys.stores) as iStore[];
      if (this.includeDeactivated === false) stores = stores.filter((x) => x.active === true);
      if (this.includeDeactivated === true) stores = stores.filter((x) => x.active === false);
      if (this.type === "Store") stores = stores.filter((x) => !x.isSupplier);
      if (this.type === "Supplier") stores = stores.filter((x) => x.isSupplier);
      return stores;
    },
    tableHeaders() {
      if (this.type === "Supplier") {
        return [
          { text: "Name", value: "name" },
          { text: "Number", value: "supplierNumber" },
          { text: "Delivery Location", value: "deliveryLocationName" },
          {
            text: "Actions",
            value: "actions",
            align: "end",
            sortable: false,
          },
        ];
      }

      return [
        { text: "Code", value: "code" },
        { text: "Name", value: "name" },
        { text: "Type", value: "storeType" },
        { text: "Delivery Location", value: "deliveryLocationName" },
        { text: "Retail Operations Manager", value: "rom" },
        {
          text: "Actions",
          value: "actions",
          align: "end",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    hasRole(roles: authRole[]) {
      return auth.hasRole(roles);
    },
    deliveryLocationNameById(id: number): string {
      var locations = dataStore.getAll(storeKeys.deliveryLocations) as iDeliveryLocation[];
      var deliveryLocation = locations.find((x: iDeliveryLocation) => x.id === id);
      if (deliveryLocation) return deliveryLocation.name;
      else return id.toString();
    },
    openStoreCodeEdit(item: iStore) {
      this.storeCodeEditItem = item;
    },
    setItemToEdit(item: iStore) {
      this.itemToEdit = item;
      this.showUpdateDialog = true;
    },
    setItemToReactivate(item: iStore) {
      this.itemToReactivate = item;
      this.showReactivateDialog = true;
    },
    setItemToDeactivate(item: iStore) {
      this.itemToDeactivate = item;
      this.showDeactivateDialog = true;
    },
    doReactivate() {
      const store = this.itemToReactivate as iStore;
      if (!store) return;
      mgr.stores.update(store.id, { active: true }).then(() => {
        flash.success("Store successfully Reactivated");
      });
      this.showReactivateDialog = false;
    },
    doDeactivate() {
      const store = this.itemToDeactivate as iStore;
      if (!store) return;
      mgr.stores.update(store.id, { active: false }).then(() => {
        flash.success("Store successfully Deactivated");
      });
      this.showDeactivateDialog = false;
    },
    exportToFile() {
      this.busyExporting = true;
      const fileName = `${this.type}.csv`;
      downloadJsonAsCsv(this.formattedTableData, fileName);
      this.busyExporting = false;
    },
  },
  beforeMount() {
    mgr.stores.fetch().finally(() => {
      this.loading = false;
    });
    mgr.deliveryLocations.fetchIfEmpty();
  },
});
