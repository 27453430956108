import { authRole } from '../types';
import axios from "axios"

export const roles = {
  fetch() {
    return axios.get("/api/roles/").then((res) => {
      return res.data;
    });
  },
  displayName(role: authRole): string {
    const str = role.toString().replaceAll("-", " ")
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};
