
import Vue from "vue";
import { mgr } from "../../managers";
import { dataStore, storeKeys } from "../../plugins/dataStore";
import { validation } from "../../mixins/Validation";
import { flash } from "../../plugins/flashNotifications";

import { iDeliveryLocation, storeType } from "../../types";

export default Vue.extend({
  name: "StoresCreate",
  components: {},
  props: { type: { type: String as () => "Store" | "Supplier", default: "Store" } },
  data() {
    return {
      live: {},
      isValidForm: false,
    };
  },
  computed: {
    storeTypeOptions() {
      return Object.values(storeType);
    },
    deliveryLocationIdOptions() {
      const locations = dataStore.getAll(storeKeys.deliveryLocations) as iDeliveryLocation[];
      return (
        locations
          // .filter((x) => x.suppliers.length == 0)
          .map((x) => {
            return { text: x.name, value: x.id };
          })
      );
    },
  },
  methods: {
    ...validation,
    close() {
      this.$emit("close");
    },
    create() {
      if (!(this.$refs["create-form"] as any).validate()) return;
      const live: any = Object.assign({}, this.live);

      const postData: any = {
        isSupplier: this.type == "Supplier" ? true : false,
        code: live.code,
        name: live.name,
        storeType: live.storeType,
        supplierNumber: live.supplierNumber,
        deliveryLocationId: live.deliveryLocationId,
        rom: live.rom,
      };

      mgr.stores.create(postData).then(() => {
        flash.success(`${this.type} successfully added`);
        this.reset();
      });

      this.close();
    },
    reset() {
      (this.$refs["create-form"] as any).reset();
      this.live = {};
    },
  },
  beforeMount() {
    mgr.stores.fetch();
    mgr.deliveryLocations.fetch();
  },
});
