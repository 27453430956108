import { BaseManager } from "./baseManager";
import { storeKeys } from "../plugins/dataStore";
import { iStoreLimitation } from '../types';

export interface iStoreLimitationCreate {
  storeId: number;
  rigid: boolean;
  max11M: boolean;
  max13M: boolean;
  max15M: boolean;
  tLift: boolean;
  nonTLift: boolean;
  airBags: boolean;
  groundOffLoading: boolean;
  dockLevelOffLoading: boolean;
  rolltainerOnly: boolean;
}

export interface iStoreLimitationUpdate {
  storeId?: number;
  rigid?: boolean;
  max11M?: boolean;
  max13M?: boolean;
  max15M?: boolean;
  tLift?: boolean;
  nonTLift?: boolean;
  airBags?: boolean;
  groundOffLoading?: boolean;
  dockLevelOffLoading?: boolean;
  rolltainerOnly?: boolean;
}

class StoreLimitationsManager extends BaseManager<iStoreLimitation, iStoreLimitationCreate, iStoreLimitationUpdate> { }

export const storeLimitations = new StoreLimitationsManager("/api/store-limitations/", storeKeys.storeLimitations);
