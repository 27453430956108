import { iDeliveryRoute, iIssueGroup } from "../types";
import { deliveryRoutes } from "./issuesDeliveryRoutes"
import { deliveryStops } from "./issuesDeliveryStops"
import { offloadEvents } from "./issuesOffloadEvents"
import { mgr } from ".";

export const issues = {
  deliveryRoutes: deliveryRoutes,
  deliveryStops: deliveryStops,
  offloadEvents: offloadEvents,
  validateEntireRoute(deliveryRoute: iDeliveryRoute) {
    // console.log("validating route")
    const deliveryStopsForRoute = mgr.deliveryStops.getAll().filter(a => a.deliveryRouteId == deliveryRoute.id)
    const routeIssues = this.deliveryRoutes.validate(deliveryRoute, deliveryStopsForRoute)

    const missing = routeIssues.missing
    const errors = routeIssues.errors
    const warnings = routeIssues.warnings

    deliveryStopsForRoute.forEach(stop => {
      const offloadEventsForStop = mgr.offloadEvents.getAll().filter(x => x.deliveryStopId == stop.id)
      const issues = this.deliveryStops.validate(stop, offloadEventsForStop)
      missing.push(...issues.missing)
      errors.push(...issues.errors)
      warnings.push(...issues.warnings)
      offloadEventsForStop.forEach(offload => {
        const issues = this.offloadEvents.validate(offload)
        missing.push(...issues.missing)
        errors.push(...issues.errors)
        warnings.push(...issues.warnings)
      })
    })
    return { missing, errors, warnings }
  }
}

// ------------------------------------
// Helper functions
// ------------------------------------

export function multiPush(root: any, group: iIssueGroup, obj: any, objName: string, key: string, extraPara?: any) {
  if (root[key]?.missing) {
    const msg = root[key]?.missing(obj, extraPara)
    if (msg != null) group.missing.push({ msg, id: obj.id, key, val: obj[key], obj: objName })
  }
  if (root[key]?.errors) {
    const msg = root[key]?.errors(obj, extraPara)
    if (msg != null) group.errors.push({ msg, id: obj.id, key, val: obj[key], obj: objName })
  }
  if (root[key]?.warnings) {
    const msg = root[key]?.warnings(obj, extraPara)
    if (msg != null) group.warnings.push({ msg, id: obj.id, key, val: obj[key], obj: objName })
  }
} 
