import { BaseManager } from "./baseManager";
import { iPicker, pickerPosition } from '../types';
import axios from "axios";
import { dataStore, storeKeys } from "../plugins/dataStore";

export interface iPickerCreate {
  id: number;
  name: string;
  employeeCode: number;
  position: pickerPosition;
}

export interface iPickerUpdate {
  id?: number;
  name?: string;
  employeeCode?: number;
  position?: pickerPosition;
  active?: boolean;
}

class PickerManager extends BaseManager<iPicker, iPickerCreate, iPickerUpdate> {
  fetchForMonth(year: number, month: number) {
    return axios.get(`${this.baseUrl}for-month/?year=${year}&month=${month}`).then((res) => {
      if (!res) return
      dataStore.replace(this.storeKey, res.data.map((x: any) => this.transformInbound(x)));
    });
  }
}

export const pickers = new PickerManager("/api/pickers/", storeKeys.pickers);
