import { deliveryType, iDeliveryStop, iIssueGroup, iOffloadEvent } from "../types"
import moment from "moment";
import { multiPush } from "./issues"
import { mgr } from ".";
import { formatSeconds } from "../utils/time";
export const deliveryStops = {
  validate(x: iDeliveryStop, eventsForStop?: iOffloadEvent[]): iIssueGroup {
    const group: iIssueGroup = { missing: [], errors: [], warnings: [] }
    multiPush(this, group, x, "deliveryStop", "stopNum", eventsForStop)
    multiPush(this, group, x, "deliveryStop", "deliveryType", eventsForStop)
    multiPush(this, group, x, "deliveryStop", "stopType", eventsForStop)
    multiPush(this, group, x, "deliveryStop", "arrivedUtc", eventsForStop)
    multiPush(this, group, x, "deliveryStop", "departedUtc", eventsForStop)
    multiPush(this, group, x, "deliveryStop", "scheduledArrivalUtc", eventsForStop)
    multiPush(this, group, x, "deliveryStop", "loadType", eventsForStop)
    return group
  },
  stopNum: {
    missing(x: iDeliveryStop) { return !x.stopNum ? "Missing" : null },
    errors(x: iDeliveryStop) {
      // don't validate on empty string
      if (x.stopNum.toString() === "") return null;
      // There must be no duplicates
      const deliveryStopsForRoute = mgr.deliveryStops.getAll().filter(a => a.deliveryRouteId == x.deliveryRouteId)
      const nonMatching = deliveryStopsForRoute.filter(
        (a: iDeliveryStop) =>
          parseFloat(a.stopNum.toString().trim()) !== parseFloat(x.stopNum.toString())
      );
      if (nonMatching.length + 1 !== deliveryStopsForRoute.length) return "Duplicate stop number";
      if (x.stopNum.toString() === "") return null;
      // if there is only one its number should be 1
      if (deliveryStopsForRoute.length === 1 && x.stopNum.toString().trim() !== "1")
        return "Stop number should be '1'";
      return null;
    },
    missingOrErrors(x: iDeliveryStop) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    }
  },
  stopType: { missing(x: iDeliveryStop) { return !x.stopType ? "Missing" : null }, missingOrErrors(x: iDeliveryStop) { return this.missing(x) } },
  deliveryLocationId: { missing(x: iDeliveryStop) { return !x.deliveryLocationId ? "Missing" : null }, missingOrErrors(x: iDeliveryStop) { return this.missing(x) } },
  loadType: {
    missing(x: iDeliveryStop) {
      // loadType can be missing is deliveryType is backhaul
      return !x.loadType && x.deliveryType != deliveryType.backhaul ? "Missing" : null
    },
    missingOrErrors(x: iDeliveryStop) { return this.missing(x) }
  },
  deliveryType: { missing(x: iDeliveryStop) { return !x.deliveryType ? "Missing" : null }, missingOrErrors(x: iDeliveryStop) { return this.missing(x) } },
  scheduledArrivalUtc: {
    missing(x: iDeliveryStop) {
      // If deliveryType is scheduled there must be a scheduledArrivalUtc
      // If stopNumber > 0 scheduledArrivalUtc can be missing
      return !x.scheduledArrivalUtc && (x.deliveryType == deliveryType.scheduled && x.stopNum < 1) ? "Missing" : null
    },
    errors(x: iDeliveryStop) {
      // Only validate if scheduled
      if (x.deliveryType !== deliveryType.scheduled) return null;

      const scheduledArrivalUtc = !x.scheduledArrivalUtc || x.scheduledArrivalUtc === "" ? null : x.scheduledArrivalUtc;
      if (x.deliveryType == null && scheduledArrivalUtc === null) return null;

      if (x.deliveryType === deliveryType.scheduled && scheduledArrivalUtc === null)
        return "Must have a time if the delivery type is: scheduled";
      return null;
    },
    missingOrErrors(x: iDeliveryStop) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    }
  },
  arrivedUtc: {
    missing(x: iDeliveryStop) { return !x.arrivedUtc ? "Missing" : null },
    errors(x: iDeliveryStop, eventsForStop?: iOffloadEvent[]) {
      if (!x.departedUtc) return null;
      if (!eventsForStop) eventsForStop = mgr.offloadEvents.getAll().filter(a => a.deliveryStopId == x.id)
      const deliveryStopsForRoute = mgr.deliveryStops.getAll().filter(a => a.deliveryRouteId == x.id)
      // Arrives always before any starts
      const errors: string[] = [];
      eventsForStop.forEach((offloadEvent: iOffloadEvent) => {
        if (moment(offloadEvent.startedUtc).isBefore(x.arrivedUtc))
          errors.push("Started before Arrived");
        if (moment(offloadEvent.endedUtc).isSameOrBefore(x.arrivedUtc))
          errors.push("Ended before Arrived");
      });
      if (errors.length > 0) return errors.join(" & ");
      // not arriving after a later stop
      const stopsInOrder: iDeliveryStop[] = deliveryStopsForRoute.sort((a: iDeliveryStop, b: iDeliveryStop) =>
        a.stopNum > b.stopNum ? 1 : b.stopNum > a.stopNum ? -1 : 0
      );
      const nextStopIndex = stopsInOrder.findIndex((x: iDeliveryStop) => x.id === x.id);
      if (nextStopIndex === -1 || nextStopIndex + 1 >= stopsInOrder.length) return null;
      const nextStop = stopsInOrder[nextStopIndex + 1];
      if (moment(nextStop.arrivedUtc).isSameOrBefore(x.arrivedUtc)) return "Arrives after a later stop";
      return null;
    },
    warnings(x: iDeliveryStop, eventsForStop?: iOffloadEvent[]) {
      if (!x.departedUtc || !x.arrivedUtc) return null
      if (!eventsForStop) eventsForStop = mgr.offloadEvents.getAll().filter(a => a.deliveryStopId == x.id)
      if (preDeadTimeDurationIsExtreme(x, eventsForStop)) {
        const seconds = preDeadTimeDuration(x, eventsForStop)
        if (Number.isNaN(seconds)) return null
        return `${formatSeconds(seconds)} between arriving and starting to offload`
      }
      return null;
    },
    missingOrErrors(x: iDeliveryStop) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    }
  },
  departedUtc: {
    missing(x: iDeliveryStop) { return !x.departedUtc ? "Missing" : null },
    errors(x: iDeliveryStop, eventsForStop?: iOffloadEvent[]) {
      if (!x.arrivedUtc) return null;
      if (!eventsForStop) eventsForStop = mgr.offloadEvents.getAll().filter(a => a.deliveryStopId == x.id)
      if (moment(x.departedUtc).isSameOrBefore(x.arrivedUtc))
        return "Departed before Arrived";
      // must depart after any events started and ended
      const errors: string[] = [];
      eventsForStop.forEach((offloadEvent: iOffloadEvent) => {
        if (moment(x.departedUtc).isSameOrBefore(offloadEvent.startedUtc))
          errors.push("Departed before Started");
        if (moment(x.departedUtc).isBefore(offloadEvent.endedUtc)) errors.push("Departed before Ended");
      });
      if (errors.length > 0) return errors.join(" & ");
      return null;
    },
    warnings(x: iDeliveryStop, eventsForStop?: iOffloadEvent[]) {
      if (!x.departedUtc || !x.arrivedUtc) return null
      if (!eventsForStop) eventsForStop = mgr.offloadEvents.getAll().filter(a => a.deliveryStopId == x.id)
      if (postDeadTimeDurationIsExtreme(x, eventsForStop)) {
        const seconds = postDeadTimeDuration(x, eventsForStop)
        if (Number.isNaN(seconds)) return null
        return `${formatSeconds(seconds)} between ending and leaving`
      }
      return null;
    },
    missingOrErrors(x: iDeliveryStop) {
      const msg = this.missing(x)
      return msg != null ? msg : this.errors(x)
    }
  },
}


function preDeadTimeDuration(deliveryStop: iDeliveryStop, offloadEventsForStop: iOffloadEvent[]): number {
  // the number of seconds from arrived to firstOffload event started
  if (offloadEventsForStop.length < 1) return 0;
  const firstOffloadEvent = offloadEventsForStop[0];
  const arrived = moment(deliveryStop.arrivedUtc);
  const started = moment(firstOffloadEvent.startedUtc);
  return started.diff(arrived, "seconds");
}
function postDeadTimeDuration(deliveryStop: iDeliveryStop, offloadEventsForStop: iOffloadEvent[]): number {
  // the number of seconds from last offloadEvent.ended to departed
  if (offloadEventsForStop.length < 1) return 0;
  const lastOffloadEvent = offloadEventsForStop[offloadEventsForStop.length - 1];
  const departed = moment(deliveryStop.departedUtc);
  const ended = moment(lastOffloadEvent.endedUtc);
  return departed.diff(ended, "seconds");
}
function preDeadTimeDurationIsExtreme(deliveryStop: iDeliveryStop, offloadEventsForStop: iOffloadEvent[]): boolean {
  const duration = preDeadTimeDuration(deliveryStop, offloadEventsForStop);
  if (duration < 60 || duration > 10 * 60) return true;
  return false;
}
function postDeadTimeDurationIsExtreme(deliveryStop: iDeliveryStop, offloadEventsForStop: iOffloadEvent[]): boolean {
  const duration = postDeadTimeDuration(deliveryStop, offloadEventsForStop);
  if (duration < 60 || duration > 30 * 60) return true;
  return false;
}
