import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { dayOfWeek, deliveryScheduleType, iScheduledDelivery, loadType } from '../types';
import axios from "axios"
import { timeOfDay } from "../utils/time";


export interface iScheduledDeliveryCreate {
  route?: number;
  deliveryLocationId: number;
  day: dayOfWeek;
  loadType: loadType;
  sdt: string;
  sat: string;
  stores: number[]
  scheduleType: deliveryScheduleType
}

export interface iScheduledDeliveryUpdate {
  route?: number | null;
  deliveryLocationId?: number;
  deliveryLocationName?: string;
  day?: dayOfWeek;
  loadType?: loadType;
  sdt?: string;
  sat?: string;
  stores?: number[]
}

class ScheduledDeliveryManager extends BaseManager<iScheduledDelivery, iScheduledDeliveryCreate, iScheduledDeliveryUpdate> {
  transformInbound(data: any) {
    if (data.sdt) data.sdt = timeOfDay.isoTimeStringToTimeOfDayString(data.sdt);
    if (data.sat) data.sat = timeOfDay.isoTimeStringToTimeOfDayString(data.sat);
    return data
  }

  transformOutbound(data: any) {
    if (data.sdt) data.sdt = timeOfDay.timeOfDayStringToIsoString(data.sdt);
    if (data.sat) data.sat = timeOfDay.timeOfDayStringToIsoString(data.sat);
    return data
  }

  getAllForType(scheduledTyp: deliveryScheduleType): iScheduledDelivery[] {
    return super.getAll().filter(x => x.scheduleType == scheduledTyp);
  }


  fetchForType(scheduledTyp: deliveryScheduleType) {
    return axios.get(this.baseUrl + "?schedule_type=" + scheduledTyp).then((res) => {
      if (!res) return
      dataStore.upsert(this.storeKey, res.data.map((x: any) => this.transformInbound(x)).sort((a: iScheduledDelivery, b: iScheduledDelivery) =>
        a.deliveryLocationName.localeCompare(b.deliveryLocationName)
      ));
    });
  }

  fetch(url = this.baseUrl) {
    return axios.get(url).then((res) => {
      if (!res) return
      dataStore.replace(this.storeKey, res.data.map((x: any) => this.transformInbound(x)).sort((a: iScheduledDelivery, b: iScheduledDelivery) =>
        a.deliveryLocationName.localeCompare(b.deliveryLocationName)
      ));
    });
  }

  download() {
    return axios.get(`${this.baseUrl}download/`).then((res) => {
      return res.data.map((x: any) => this.transformInbound(x)) as any
    });
  }
}

export const scheduledDeliveries = new ScheduledDeliveryManager("/api/scheduled-deliveries/", storeKeys.scheduledDeliveries);
