import { alerts } from "./alerts"
import { apikeys } from "./apikeys"
import { deliveryLocations } from "./deliveryLocations"
import { deliveryReports } from "./deliveryReports"
import { deliveryRoutes } from "./deliveryRoutes"
import { deliveryStops } from "./deliveryStops"
import { departments } from "./departments"
import { drivers } from "./drivers"
import { driverSchedules } from "./driverSchedules"
import { fmProviders } from "./fmProviders"
import { offloadContainerGroups } from "./offloadContainerGroups"
import { offloadEvents } from "./offloadEvents"
import { organisations } from "./organisations"
import { overtimeTypes } from "./overtimeTypes"

import { penaltyTypes } from "./penaltyTypes"
import { pickerIncentiveBands } from "./pickerIncentiveBands"
import { pickerOvertime } from "./pickerOvertime"
import { pickerPenalties } from "./pickerPenalties"
import { pickers } from "./pickers"
import { roles } from "./roles"
import { scheduledDeliveries } from "./scheduledDeliveries"
import { sections } from "./sections"
import { storeLimitations } from "./storeLimitations"
import { stores } from "./stores"
import { telemetry } from "./telemetry"
import { users } from "./users"
import { vehicles } from "./vehicles"
import { issues } from "./issues"

import { transporters } from "./transporters"
import { transporterRouteAgreements } from "./transporterRouteAgreements"
import { transporterBaseCpks } from "./transporterBaseCpks"
import { transporterCostReconciliations } from "./transporterCostReconciliation"

import { smsStatus } from "./smsStatus"

export const mgr = {
  alerts,
  apikeys,
  deliveryLocations,
  deliveryReports,
  deliveryRoutes,
  deliveryStops,
  departments,
  drivers,
  driverSchedules,
  fmProviders,
  offloadContainerGroups,
  offloadEvents,
  organisations,
  overtimeTypes,
  penaltyTypes,
  pickerIncentiveBands,
  pickerOvertime,
  pickerPenalties,
  pickers,
  roles,
  scheduledDeliveries,
  sections,
  storeLimitations,
  stores,
  telemetry,
  users,
  vehicles,
  transporters,
  transporterRouteAgreements,
  transporterBaseCpks,
  transporterCostReconciliations,
  issues,
  smsStatus
}