import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { iOffloadEvent } from '../types';
import { localToUtc, utcToLocal } from '../utils/time';
import axios from "axios"

export interface iOffloadEventCreate {
  deliveryStopId: number;
  startedUtc: string;
  endedUtc: string;
}

export interface iOffloadEventUpdate {
  deliveryStopId?: number;
  startedUtc?: string;
  endedUtc?: string;
}

class OffloadEventManager extends BaseManager<iOffloadEvent, iOffloadEventCreate, iOffloadEventUpdate> {
  transformInbound(data: any) {
    data.startedUtc = utcToLocal(data.startedUtc);
    data.endedUtc = utcToLocal(data.endedUtc);
    return data
  }

  transformOutbound(data: any) {
    if (data.startedUtc) data.startedUtc = localToUtc(data.startedUtc);
    if (data.endedUtc) data.endedUtc = localToUtc(data.endedUtc);
    return data
  }

  update(id: number, data: iOffloadEventUpdate, merge = false, url = `${this.baseUrl}${id}/`) {
    return axios.patch(url, this.transformOutbound(data)).then((res) => {
      if (!res) return
      res.data = this.transformInbound(res.data)
      dataStore.upsert(storeKeys.offloadEvents, [res.data]);
      dataStore.upsert(storeKeys.offloadEventsInitial, [res.data]);
      dataStore.remove(storeKeys.offloadEventsEdits, [res.data]);
    });
  }
}

export const offloadEvents = new OffloadEventManager("/api/offload-events/", storeKeys.offloadEvents);
