
import { iDeliveryRoute, iDeliveryStop, iOffloadContainerGroup, iOffloadEvent } from '../types';
import { dataStore, storeKeys } from "../plugins/dataStore";
import axios from "axios"
import { deliveryRoutes } from "./deliveryRoutes"
import { deliveryStops } from "./deliveryStops"
import { offloadEvents } from "./offloadEvents"

export const deliveryReports = {
  fetch(date: string) {
    return axios.get(`/api/delivery-reports/${date}/  `).then((res) => {
      if (!res) return

      // Format incoming data
      const deliveryRoutesData: iDeliveryRoute[] = res.data.deliveryRoutes.map((x: any) => deliveryRoutes.transformInbound(x));
      const deliveryStopsData: iDeliveryStop[] = res.data.deliveryStops
        .map((x: iDeliveryStop) => deliveryStops.transformInbound(x))
        .sort((a: iDeliveryStop, b: iDeliveryStop) => (a.stopNum > b.stopNum ? 1 : b.stopNum > a.stopNum ? -1 : 0));

      const offloadEventsData: iOffloadEvent[] = res.data.offloadEvents.map((x: iOffloadEvent) => offloadEvents.transformInbound(x));
      const offloadContainerGroupsData: iOffloadContainerGroup[] = res.data.offloadContainerGroups;

      // Insert data that will be edited
      dataStore.replace(storeKeys.deliveryRoutes, deliveryRoutesData);
      dataStore.replace(storeKeys.deliveryStops, deliveryStopsData);
      dataStore.replace(storeKeys.offloadEvents, offloadEventsData);
      dataStore.replace(storeKeys.offloadContainerGroups, offloadContainerGroupsData);

      // Insert data that will not be edited
      dataStore.replace(storeKeys.deliveryRoutesInitial, deliveryRoutesData);
      dataStore.replace(storeKeys.deliveryStopsInitial, deliveryStopsData);
      dataStore.replace(storeKeys.offloadEventsInitial, offloadEventsData);
      dataStore.replace(storeKeys.offloadContainerGroupsInitial, offloadContainerGroupsData);

      // Return the data from the store, not that actual response data
      return {
        deliveryRoutes: dataStore.getAll(storeKeys.deliveryRoutes),
        deliveryStops: dataStore.getAll(storeKeys.deliveryStops),
        offloadEvents: dataStore.getAll(storeKeys.offloadEvents),
        offloadContainerGroups: dataStore.getAll(storeKeys.offloadContainerGroups),
      };
    });
  },
  getMissingFields(date: string) {
    return axios.get(`/api/delivery-reports/missing-fields/${date}/`).then((res) => {
      return res.data as any;
    });
  }
}