import axios from "axios";
import { dataStore, storeKeys } from "../plugins/dataStore";


export class BaseManager<objType, createType, updateType>  {
  protected readonly baseUrl: string;
  protected readonly storeKey: storeKeys;

  constructor(baseUrl: string, storeKey: storeKeys) {
    this.baseUrl = baseUrl;
    this.storeKey = storeKey;
  }

  /**
  * Is called when data arrives back from the api
  */
  protected transformInbound(data: any): objType {
    return data
  }

  /**
  * Is called before the data is sent to the api
  */
  protected transformOutbound(data: any): createType | updateType {
    return data
  }

  /**
   * Retrieves all the items of that type from the datastore
   */
  getAll(): objType[] {
    return dataStore.getAll(this.storeKey) as objType[]
  }

  /**
   * Retrieves one the item of that type from the datastore
   */
  getById(id: number) {
    return dataStore.getById(this.storeKey, id) as objType | null
  }

  /**
   * Replace all the items of that type in the datastore
   */
  replace(items: objType[]) {
    dataStore.replace(this.storeKey, items)
  }

  /**
   * Replaces, updates or adds one item of that type in the datastore. 
   * If there is no matching item, it will add the item.
   * If there is a matching item it will be replaced.
   * If there is a matching item and 'merge' is true it will update the item
   */
  upsert(items: objType[], merge = false) {
    dataStore.upsert(this.storeKey, items, merge)
  }

  /**
   * Removes all the items of that type in the datastore
   */
  remove(items: objType[]) {
    dataStore.remove(this.storeKey, items)
  }

  /**
   * Sends a GET request to the api and replaces the dataStore with the results
   */
  fetch(url = this.baseUrl) {
    return axios.get(url).then((res) => {
      if (!res) return
      dataStore.replace(this.storeKey, res.data.map((x: any) => this.transformInbound(x)));
    });
  }

  /**
   * If the dataStore is empty then call the .fetchAll() function
   */
  fetchIfEmpty(url = this.baseUrl) {
    if (dataStore.getAll(this.storeKey).length == 0) {
      this.fetch(url);
    }
  }

  /**
   * Sends a POST request to the api and upserts the results to the dataStore
   */
  create(data: createType, url = this.baseUrl) {
    return axios.post(url, this.transformOutbound(data)).then((res) => {
      if (!res) return
      dataStore.upsert(this.storeKey, [this.transformInbound(res.data)]);
    });
  }

  /**
   * Sends a PATCH request to the api and upserts the results to the dataStore
   */
  update(id: number, data: updateType, merge = false, url = `${this.baseUrl}${id}/`) {
    return axios.patch(url, this.transformOutbound(data)).then((res) => {
      if (!res) return
      dataStore.upsert(this.storeKey, [this.transformInbound(res.data)], merge);
    })
  }

  /**
   * Sends a DELETE request to the api and removes item from the dataStore
   */
  delete(id: number, url = `${this.baseUrl}${id}/`) {
    return axios.delete(url).then((res) => {
      const item = dataStore.getById(this.storeKey, id);
      dataStore.remove(this.storeKey, [item]);
    });
  }
}
