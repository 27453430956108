import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { driverScheduleType, iDriverSchedule } from '../types';
import { localToUtc, utcToLocal } from '../utils/time';
import axios from "axios"

export interface iDriverScheduleCreate {
  date: string;
  driverId: number;
  scheduleType: driverScheduleType;
  scheduledShiftStartUtc?: string;
  completedShift?: boolean;
  scheduledFinalised?: boolean;
}

export interface iDriverScheduleUpdate {
  driverId?: number;
  scheduleType?: driverScheduleType;
  scheduledShiftStartUtc?: string;
  shiftStartUtc?: string;
  shiftEndUtc?: string;
  completedShift?: boolean;
}

export interface iDriverScheduleReSchedule {
  scheduleType?: driverScheduleType;
  scheduledShiftStartUtc?: string;
}

class DriverScheduleManager extends BaseManager<iDriverSchedule, iDriverScheduleCreate, iDriverScheduleUpdate> {
  transformInbound(data: any) {
    if (data.scheduledShiftStartUtc) data.scheduledShiftStartUtc = utcToLocal(data.scheduledShiftStartUtc);
    if (data.shiftStartUtc) data.shiftStartUtc = utcToLocal(data.shiftStartUtc);
    if (data.shiftEndUtc) data.shiftEndUtc = utcToLocal(data.shiftEndUtc);
    if (data.returnedUtc) data.returnedUtc = utcToLocal(data.returnedUtc);
    return data
  }

  transformOutbound(data: any) {
    if (data.scheduledShiftStartUtc) data.scheduledShiftStartUtc = localToUtc(data.scheduledShiftStartUtc);
    if (data.shiftStartUtc) data.shiftStartUtc = localToUtc(data.shiftStartUtc);
    if (data.shiftEndUtc) data.shiftEndUtc = localToUtc(data.shiftEndUtc);
    if (data.returnedUtc) data.returnedUtc = localToUtc(data.returnedUtc);
    return data
  }

  fetch(date: string) {
    return axios.get(`${this.baseUrl}${date}/`).then((res) => {
      if (!res) return
      dataStore.replace(this.storeKey, res.data.map((x: any) => this.transformInbound(x)));
    });
  }

  fetchUpsert(date: string) {
    return axios.get(`${this.baseUrl}${date}/`).then((res) => {
      if (!res) return
      dataStore.upsert(this.storeKey, res.data.map((x: any) => this.transformInbound(x)));
    });
  }

  finaliseSchedules(schedules: iDriverSchedule[]) {
    const ids = schedules.map(x => x.id)
    return axios.patch(`${this.baseUrl}finalise-schedules/`, { ids: ids }).then((res) => {
      if (!res) return
      const data = res.data.map((x: any) => this.transformInbound(x))
      dataStore.upsert(this.storeKey, data);
    });
  }

  finaliseReports(date: string) {
    return axios.patch(`${this.baseUrl}finalise-reports/${date}/`).then((res) => {
      if (!res) return
      const data = res.data.map((x: any) => this.transformInbound(x))
      dataStore.upsert(this.storeKey, data);
    });
  }

  unFinaliseReports(date: string) {
    return axios.patch(`${this.baseUrl}un-finalise-reports/${date}/`).then((res) => {
      if (!res) return
      const data = res.data.map((x: any) => this.transformInbound(x))
      dataStore.upsert(this.storeKey, data);
    });
  }

  updateReturnToDcTimes(date: string) {
    return axios.patch(`${this.baseUrl}refresh-return-to-dc-times/${date}/`).then((res) => {
      if (!res) return
      const data = res.data.map((x: any) => this.transformInbound(x))
      dataStore.upsert(this.storeKey, data);
    });
  }
  reschedule(schedule_Id: string, updated: iDriverScheduleReSchedule) {

    return axios.patch(`${this.baseUrl}reschedule/${schedule_Id}/`, updated)
      .then((res) => {
        if (!res) return
        dataStore.upsert(this.storeKey, [this.transformInbound(res.data)]);
      });
  }
}

export const driverSchedules = new DriverScheduleManager("/api/driver-schedules/", storeKeys.driverSchedules);
