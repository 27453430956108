import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { iHorse, iRigid, iTrailer, vehicleType } from "../types";
import axios from "axios";

export interface iVehicleCreate {
  code: string;
  registration?: string;
  externalLength?: number;
  fmProviderId: number | null;
}

export interface iVehicleUpdate {
  code?: string;
  registration?: string;
  active?: boolean;
  externalLength?: number;
  fmProviderId?: number | null;
}

export interface iRigidCreate extends iVehicleCreate {
  palletteCapacity?: number;
  internalWidth?: number;
  internalHeight?: number;
  internalLength?: number;
}
export interface iRigidUpdate extends iVehicleUpdate {
  palletteCapacity?: number;
  internalWidth?: number;
  internalHeight?: number;
  internalLength?: number;
}

export interface iHorseCreate extends iVehicleCreate { }
export interface iHorseUpdate extends iVehicleUpdate { }

export interface iTrailerCreate extends iVehicleCreate {
  palletteCapacity?: number;
  internalWidth?: number;
  internalHeight?: number;
  internalLength?: number;
}
export interface iTrailerUpdate extends iVehicleUpdate {
  palletteCapacity?: number;
  internalWidth?: number;
  internalHeight?: number;
  internalLength?: number;
}

class RigidManager extends BaseManager<iRigid, iRigidCreate, iRigidUpdate> { }
class HorseManager extends BaseManager<iHorse, iHorseCreate, iHorseUpdate> { }
class TrailerManager extends BaseManager<iTrailer, iTrailerCreate, iRigidUpdate> { }

export const vehicles = {
  fetch(url = "/api/vehicles/") {
    return axios.get(url).then((res) => {
      if (!res?.data) return
      const rigids = res.data?.rigids;
      const horses = res.data?.horses;
      const trailers = res.data?.trailers;
      dataStore.replace(storeKeys.rigids, rigids);
      dataStore.replace(storeKeys.horses, horses);
      dataStore.replace(storeKeys.trailers, trailers);
    });
  },
  fetchDeactivated(deactivated: boolean) {
    return this.fetch(`/api/vehicles/?deactivated=${deactivated}`)
  },
  rigids: new RigidManager("/api/vehicles/rigids/", storeKeys.rigids),
  horses: new HorseManager("/api/vehicles/horses/", storeKeys.horses),
  trailers: new TrailerManager("/api/vehicles/trailers/", storeKeys.trailers),
};
