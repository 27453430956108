import { BaseManager } from "./baseManager";
import { dataStore, storeKeys } from "../plugins/dataStore";
import { iSmsStatus } from '../types';
import { localToUtc, utcToLocal } from '../utils/time';
import Axios from "axios";

export interface iSmsStatusCreate {

}

export interface iSmsStatusUpdate {

}

class smsStatusManager extends BaseManager<iSmsStatus, iSmsStatusCreate, iSmsStatusUpdate> {
  fetchForDate(date: string) {
    return this.fetch(`${this.baseUrl}${date}/`)
  }

  transformInbound(data: any) {
    if (data.statusUtc) data.statusUtc = utcToLocal(data.statusUtc);
    if (data.sentUtc) data.sentUtc = utcToLocal(data.sentUtc);
    return data
  }

  transformOutbound(data: any) {
    if (data.statusUtc) data.statusUtc = localToUtc(data.statusUtc);
    if (data.sentUtc) data.sentUtc = localToUtc(data.sentUtc);
    return data
  }

  resendSms(selectedSms: iSmsStatus[]) {
    const ids = selectedSms.map(x => x.id)
    return Axios.post(`${this.baseUrl}re-send/`, { ids: ids })
  }
}

export const smsStatus = new smsStatusManager("/api/sms-status/", storeKeys.smsStatuses);
