import {
  iDeliveryLocation,
  iDeliveryRoute,
  iDeliveryStop,
  iOffloadContainerGroup,
  iOffloadEvent,
  iScheduledDelivery,
  iStore,
  iTelemetryReport,
  iUser,
  iPicker,
  iPickerIncentiveBand,
  iSection,
  iDepartment,
  iFmProvider,
  iRigid,
  iHorse,
  iTrailer,
  iStoreLimitation,
  iOvertimeType,
  iPickerOvertime,
  iPickerPenaltySimple,
  iPickerPenaltyDetailed,
  iPenaltyType,
  iOrganisation,
  iApikey,
  iDriver,
  iDriverSchedule,
  iTransporter,
  iSmsStatus,
} from "../types";
import { VueConstructor } from "vue/types/umd";
import { Store } from "vuex";
import clonedeep from "lodash.clonedeep";
import { iTransporterRouteAgreement } from "@/managers/transporterRouteAgreements";
import { iTransporterBaseCpk } from "@/managers/transporterBaseCpks";
import { iTransporterCostRecon } from "@/managers/transporterCostReconciliation";

/*
    // To use, in main.ts add:
    import store from "./store";
    import dataStore from "./plugins/dataStore";
    Vue.use(dataStore, { store });

    // In a .vue file
    import { dataStore, storeKeys } from "../plugins/dataStore";
    dataStore.replace(dataKeys.words, ["Store", "these", "words"]);
*/

// The list of keys that can be used to access the dataStore
// The keys need to match the names of the items in the state object
export enum storeKeys {
  stores = "stores",
  deliveryLocations = "deliveryLocations",
  scheduledDeliveries = "scheduledDeliveries",

  users = "users",
  apikeys = "apikeys",
  organisations = "organisations",
  drivers = "drivers",
  driverSchedules = "driverSchedules",

  deliveryRoutes = "deliveryRoutes",
  deliveryStops = "deliveryStops",
  offloadEvents = "offloadEvents",
  offloadContainerGroups = "offloadContainerGroups",

  deliveryRoutesInitial = "deliveryRoutesInitial",
  deliveryStopsInitial = "deliveryStopsInitial",
  offloadEventsInitial = "offloadEventsInitial",
  offloadContainerGroupsInitial = "offloadContainerGroupsInitial",

  deliveryRoutesEdits = "deliveryRoutesEdits",
  deliveryStopsEdits = "deliveryStopsEdits",
  offloadEventsEdits = "offloadEventsEdits",
  offloadContainerGroupsEdits = "offloadContainerGroupsEdits",

  telemetryReports = "telemetryReports",

  pickerIncentiveBands = "pickerIncentiveBands",
  pickers = "pickers",
  departments = "departments",
  sections = "sections",
  penaltyTypes = "penaltyTypes",
  overtimeTypes = "overtimeTypes",
  pickerOvertime = "pickerOvertime",
  pickerPenaltiesSimple = "pickerPenaltiesSimple",
  pickerPenaltiesDetailed = "pickerPenaltiesDetailed",
  pickerPenaltiesAndOvertimeEdits = "pickerPenaltiesAndOvertimeEdits",

  fmProviders = "fmProviders",
  rigids = "rigids",
  horses = "horses",
  trailers = "trailers",
  storeLimitations = "storeLimitations",

  transporters = "transporters",
  transporterRouteAgreements = "transporterRouteAgreements",
  transporterBaseCpks = "transporterBaseCpks",
  transporterCostReconciliations = "transporterCostReconciliations",

  smsStatuses = "smsStatuses",
}

interface iDataState {
  stores: iStore[];
  deliveryLocations: iDeliveryLocation[];
  scheduledDeliveries: iScheduledDelivery[];

  users: iUser[];
  apikeys: iApikey[];
  organisations: iOrganisation[];
  drivers: iDriver[];
  driverSchedules: iDriverSchedule[];

  deliveryRoutes: iDeliveryRoute[];
  deliveryStops: iDeliveryStop[];
  offloadEvents: iOffloadEvent[];
  offloadContainerGroups: iOffloadContainerGroup[];

  deliveryRoutesInitial: iDeliveryRoute[];
  deliveryStopsInitial: iDeliveryStop[];
  offloadEventsInitial: iOffloadEvent[];
  offloadContainerGroupsInitial: iOffloadContainerGroup[];

  deliveryRoutesEdits: iDeliveryRoute[];
  deliveryStopsEdits: iDeliveryStop[];
  offloadEventsEdits: iOffloadEvent[];
  offloadContainerGroupsEdits: iOffloadContainerGroup[];

  telemetryReports: iTelemetryReport[];

  pickerIncentiveBands: iPickerIncentiveBand[];
  pickers: iPicker[];
  departments: iDepartment[];
  sections: iSection[];
  overtimeTypes: iOvertimeType[];
  penaltyTypes: iPenaltyType[];
  pickerOvertime: iPickerOvertime[];
  pickerPenaltiesSimple: iPickerPenaltySimple[];
  pickerPenaltiesDetailed: iPickerPenaltyDetailed[];
  pickerPenaltiesAndOvertimeEdits: any[]

  fmProviders: iFmProvider[];
  rigids: iRigid[];
  horses: iHorse[];
  trailers: iTrailer[];
  storeLimitations: iStoreLimitation[];
  transporters: iTransporter[];
  transporterRouteAgreements: iTransporterRouteAgreement[];
  transporterBaseCpks: iTransporterBaseCpk[];
  transporterCostReconciliations: iTransporterCostRecon[];

  smsStatuses: iSmsStatus[];
}

let STORE = {} as Store<any>; // The vuex store instance after installation

export default {
  install(Vue: VueConstructor<Vue>, { store }: { store: Store<any> }) {
    if (!store) throw new Error("Please provide vuex store.");
    STORE = store;

    const state: iDataState = {
      stores: [],
      deliveryLocations: [],
      scheduledDeliveries: [],

      users: [],
      apikeys: [],
      organisations: [],
      drivers: [],
      driverSchedules: [],

      deliveryRoutes: [],
      deliveryStops: [],
      offloadEvents: [],
      offloadContainerGroups: [],

      deliveryRoutesInitial: [],
      deliveryStopsInitial: [],
      offloadEventsInitial: [],
      offloadContainerGroupsInitial: [],

      deliveryRoutesEdits: [],
      deliveryStopsEdits: [],
      offloadEventsEdits: [],
      offloadContainerGroupsEdits: [],

      telemetryReports: [],

      pickerIncentiveBands: [],
      pickers: [],
      departments: [],
      sections: [],
      overtimeTypes: [],
      pickerOvertime: [],
      penaltyTypes: [],
      pickerPenaltiesSimple: [],
      pickerPenaltiesDetailed: [],
      pickerPenaltiesAndOvertimeEdits: [],

      fmProviders: [],
      rigids: [],
      horses: [],
      trailers: [],
      storeLimitations: [],

      transporters: [],
      transporterRouteAgreements: [],
      transporterBaseCpks: [],
      transporterCostReconciliations: [],

      smsStatuses: [],
    };

    const mutations = {
      _replaceItems(state: iDataState, { items, key }: { key: storeKeys; items: any[] }) {
        // Simply overwrites the state[key] with the items provided
        if (!hasKey(state, key)) return;
        state[key] = clonedeep(items);
      },
      _removeItems(state: iDataState, { key, items }: { key: storeKeys; items: any[] }) {
        // Remove the items provided from state[key]
        if (Array.isArray(items) && items.length === 0) state[key] = [];
        items.forEach((item: any) => {
          if (item.id === undefined) return console.error("An item does not have a property [.id]");
          const index = state[key].findIndex((x: any) => x["id"] == item["id"]);
          if (index >= 0) {
            state[key].splice(index, 1);
          }
        });
      },
      _upsertItems(state: iDataState, { items, key, merge }: { key: storeKeys; items: any[]; merge: boolean }) {
        // Tries to find and update an existing item, if its cant find a match it adds the items
        if (!hasKey(state, key)) return;
        // If there are no items yet, just insert the new items and stop processing further.
        if (state[key].length === 0) {
          state[key] = items.map((x) => clonedeep(x));
          return;
        }
        // Loop over each existing item.
        // try see if the existing item matches one of the new items
        // If it does match, replace and remove the found item from the list of new items.
        // If there is no match, keep the existing item.
        let newItemsState: any[] = state[key];
        newItemsState = newItemsState.map((existingItem: any) => {
          let item: any = items.find((x) => x["id"] === existingItem["id"]);
          if (item) {
            // Remove item from list so we know if any are left later
            items = items.filter((x) => x["id"] !== item["id"]);
            // If the merge flag is true merge the existing and the new item
            if (merge) item = Object.assign(existingItem, item);
            return clonedeep(item);
          } else return clonedeep(existingItem);
        });
        // If there are any items they did not match any existing items, add them now.
        items.forEach((remainingItem) => {
          newItemsState.push(clonedeep(remainingItem));
        });
        state[key] = newItemsState;
      },
    };
    const actions = {};

    store.registerModule("dataStore", { state, mutations, actions });
  },
};

export const dataStore = {
  getById(key: storeKeys, id: number): any {
    const items: any[] = STORE.state.dataStore[key.toString()];
    return clonedeep(items.find((x: any) => x["id"] === id));
  },
  getAll(key: storeKeys): any[] {
    return clonedeep(STORE.state.dataStore[key.toString()]) || [];
  },
  getAllWithEdits(key: storeKeys): any[] {
    // Returns the items with their edits merged in
    const editKey = mapStoreKeyToEditStoreKey(key);
    const items = clonedeep(STORE.state.dataStore[key.toString()]) || [];
    const edits = clonedeep(STORE.state.dataStore[editKey.toString()]) || [];

    if (edits.length === 0) return items;
    edits.forEach((edit: any) => {
      // find match, if match, overwrite some values
      // if no match do nothing?
      const foundItem: any = items.find((x: any) => x["id"] === edit["id"]);
      if (foundItem) {
        Object.assign(foundItem, edit);
      }
    });
    return items;
  },
  replace(key: storeKeys, items: any[]): void {
    STORE.commit("_replaceItems", { key, items });
  },
  upsert(key: storeKeys, items: any[], merge = false): void {
    STORE.commit("_upsertItems", { key, items, merge });
  },
  remove(key: storeKeys, items: any[]): void {
    STORE.commit("_removeItems", { key, items });
  },
};

// ----------------------------------------------------------------------------------------
// Helper functions
// ----------------------------------------------------------------------------------------
function hasKey(obj: any, key: string): boolean {
  if (Object.keys(obj).includes(key)) return true;
  else {
    console.error(`Store does not have a key [${key}]`);
    return false;
  }
}
function mapStoreKeyToEditStoreKey(key: storeKeys): storeKeys {
  if (key === storeKeys.deliveryRoutes) return storeKeys.deliveryRoutesEdits;
  if (key === storeKeys.deliveryStops) return storeKeys.deliveryStopsEdits;
  if (key === storeKeys.offloadEvents) return storeKeys.offloadEventsEdits;
  if (key === storeKeys.offloadContainerGroups) return storeKeys.offloadContainerGroupsEdits;
  console.error(`Did not find a matching '*Edits' for 'storeKey': ${key}`);
  return key;
}
